import * as React from 'react';
import Common from '../../Util/Common';
import './HolidayList.css';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Edit,
  EditSettingsModel,
  GridComponent,
  Inject,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
// import { cssClass } from '@syncfusion/ej2-react-lists';
// import { showDialog } from '@syncfusion/ej2-react-spreadsheet';
import axios from 'axios';
import {
  ChangeEventArgs,
  CheckBoxComponent,
} from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

export default class HolidayList extends React.Component<{}> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private hDate: DatePickerComponent | any = undefined;
  private grid: GridComponent | any = undefined;
  private importGrid: GridComponent | any = undefined;
  private defaultWidth: string = '35vw';
  private importGridWidth: string = '45vw';
  private currentYearFirstDay: any = Common.formatDate(
    Common.RemoveTimezoneOffsetFromDate(
      new Date(new Date().getFullYear(), 0, 1)
    ),
    'yyyy-MM-dd'
  );
  private currentYearLastDay: any = Common.formatDate(
    Common.RemoveTimezoneOffsetFromDate(
      new Date(new Date().getFullYear(), 11, 31)
    ),
    'yyyy-MM-dd'
  );
  toastObj: any;
  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    holidays: [],
    showDialog: false,
    delholiday: [],
    isChecked: false,
    importHoliday: [],
  };

  componentDidMount() {
    if (this.state.holidays.length <= 0) {
      this.getHolidays();
    }
  }

  /*  Import the holiday Data API  */
  private async googleholiday(startDate: any, endDate: any) {
    let hcode = 'tz'; // this.user.holidayIsoCode;
    let gurl = `https://www.googleapis.com/calendar/v3/calendars/en.${hcode}%23holiday%40group.v.calendar.google.com/events?timeMin=${startDate}T00:00:00Z&timeMax=${endDate}T00:00:00Z&key=AIzaSyAt9uQQ6EdGZ_RmfzF9siG7QtghkPVi91I`;
    const res = await axios({
      method: 'get',
      url: gurl,
    });

    if (res) {
      let holidays = res.data.items.map((item: any) => ({
        name: item?.summary,
        date: item?.start?.date + 'T00:00:00Z',
        originalName: item?.summary,
      }));
      let allHolidayOriginalName = this.state.holidays.map(
        (item: any) => item.originalName
      );
      let newHoliday = holidays.filter(
        (items: any) => !allHolidayOriginalName.includes(items.originalName)
      );
      this.setState({ importHoliday: newHoliday });
    }
    return res;
  }

  /*  Get All the holiday data API  */
  private getHolidays() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          holidays: data,
        });
        this.googleholiday(this.currentYearFirstDay, this.currentYearLastDay);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  /*  Add New holiday data API  */
  private addNewHoliday(payloads: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/add`,
      payloads,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.setState({
          showDialog: false,
          isChecked: false,
          holidays: data,
          importHoliday: [],
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  /*  Update existing holiday data API  */
  private updateHolidays(payloads: any, event: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/update`,
      payloads,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let hData: any = this.state.holidays.filter(
          (ele: any) => ele.id !== data.id
        );

        hData.push(data);
        hData.sort((a: any, b: any) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });
        this.setState({ holidays: hData });
      })
      .catch((error: any) => {
        this.grid.selectRow(event.rowIndex);
        let hName: any = document.querySelector(
          '.holiday-grid input[name="name"]'
        );
        if (hName) {
          hName.value = event.previousData.name;
        }
        event.data.name = event.previousData.name;
        this.grid.startEdit();
        console.error(error);
      })
      .finally(() => {});
  }

  /*  Delete holiday data API  */
  private deleteHolidays(payloads: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/delete`,
      payloads,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.getHolidays();
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  /*  Add New Holiday Form Dialog  */
  private addHolidayForm() {
    var _content: any;
    const importCommand: any = [
      {
        type: 'Save',
        buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' },
      },
      {
        type: 'Cancel',
        buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' },
      },
    ];
    _content = (
      <>
        <div className='col align-items-center'>
          <div className='import-check-class'>
            <CheckBoxComponent
              checked={this.state.isChecked}
              label='Import'
              change={this.onChange.bind(this)}
            />
          </div>
          <div
            id='_formComponent'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <div className='holiday-new-textbox col-sm-12 my-2'>
              {/* <label>Name</label>
              <span className='mandatoryinfo'>*</span> */}
              <TextBoxComponent
                className='textbox-class'
                onFocus={this.onFocusFunction}
                placeholder='Enter Holiday Name *'
                id='holiday'
                name='holiday'
                type='text'
                autocomplete='off'
                floatLabelType='Auto'
              />
            </div>
            <div className='holiday-new-textbox col-sm-12 my-2'>
              {/* <label>Date</label>
              <span className='mandatoryinfo'>*</span> */}
              <DatePickerComponent
                id='holidaydate'
                name='holidaydate'
                className='textbox-class'
                onFocus={this.onFocusFunction}
                ref={(scope) => {
                  this.hDate = scope;
                }}
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                placeholder='Select Date *'
                floatLabelType='Auto'
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
              ></DatePickerComponent>
            </div>
          </div>
          <div id='_importGridComponent' style={{ display: 'none' }}>
            <GridComponent
              dataSource={this.state.importHoliday}
              editSettings={this.importDataEditSetting}
              ref={(scope) => {
                this.importGrid = scope;
              }}
              actionBegin={this.importActionBegin.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective field='name' headerText='Name' />
                <ColumnDirective
                  type='datetime'
                  field='date'
                  headerText='Date'
                  format='dd-MMMM-yyyy'
                  editType='datepickeredit'
                />
                <ColumnDirective width='85' commands={importCommand} />
              </ColumnsDirective>
              <Inject services={[CommandColumn]} />
            </GridComponent>
          </div>
        </div>
      </>
    );
    return _content;
  }

  /*  Action Start with Grid Component  */
  private actionBegin(e: any) {
    if (e.requestType === 'save') {
      let payloads: any = null;
      payloads = {
        id: e.data.id,
        name: e.data.name,
        date: e.data.date,
      };
      this.updateHolidays(payloads, e);
    } else if (e.requestType === 'delete') {
      this.setState({ delholiday: e.data[0] });
      let delPalyloads: any = null;
      delPalyloads = [
        {
          id: e.data[0].id,
        },
      ];
      this.deleteHolidays(delPalyloads);
    }
  }

  private importActionBegin(e: any) {
    if (e.requestType == 'save') {
      let payloads: any = null;
      payloads = [
        {
          name: e.data.name,
          date: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(e.data.date),
            'yyyy-MM-dd'
          ),
          originalName: e.data.name,
        },
      ];
      this.setState({ importHoliday: payloads });
    }
  }

  /*  Toolbar option Add button  */
  private toolbarClick(args: any): void {
    switch (args.item.id) {
      case 'cadd':
        this.setState({ showDialog: true });
        break;
    }
  }

  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#holiday'),
      document.querySelector<HTMLInputElement>('#holidaydate'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  /*  onChange Event on Import holiday Data  */
  private onChange(args: ChangeEventArgs) {
    this.setState({ isChecked: args.checked });
    let formComponent: any = document.getElementById('_formComponent');
    let gridComponent: any = document.getElementById('_importGridComponent');
    if (this.state.isChecked) {
      formComponent.style.display = 'none';
      gridComponent.style.display = '';
    } else {
      formComponent.style.display = '';
      gridComponent.style.display = 'none';
    }
  }

  /*  Dialogbox Accept and Cancel button  */
  private DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.showDialog === true) {
          this.setState({ showDialog: false, isChecked: false });
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        this.ValidateForm();
        if (this.state.isChecked) {
          if (this.state.importHoliday.length > 0) {
            this.addNewHoliday(this.state.importHoliday);
          } else {
            // DialogConfirm.showDialog({
            //   content: '<p class="dialog-contain">No record found.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: 'No record found.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          }
        } else {
          if (document.getElementById('holiday')) {
            if ((document.getElementById('holiday') as any).value == '') {
              // DialogConfirm.showDialog({
              //   content: '<p class="dialog-contain">Please enter name</p>',
              // });
              const TargetInput =
                document.querySelector<HTMLInputElement>('#holiday');
              if (TargetInput) {
                TargetInput.style.borderBottom = '2px solid red';
              }
              return;
            }
          }

          if (this.hDate.value == null) {
            //   content: '<p class="dialog-contain">Please enter date</p>',
            // });
            const TargetInput =
              document.querySelector<HTMLInputElement>('#holidaydate');
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            return;
          }
          let payloads: any = [
            {
              name: (document.getElementById('holiday') as any).value,
              date: Common.formatDate(
                Common.RemoveTimezoneOffsetFromDate(this.hDate.value),
                'yyyy-MM-dd'
              ),
              originalName: (document.getElementById('holiday') as any).value,
            },
          ];
          this.addNewHoliday(payloads);
        }
      },
    },
  ];

  public importDataEditSetting: EditSettingsModel = {
    allowEditing: true,
    allowEditOnDblClick: true,
  };

  render() {
    const commands: any = [
      {
        type: 'Edit',
        buttonOption: {
          iconCss: 'e-icons e-edit',
          cssClass: 'e-tbar-btn e-btn',
        },
      },
      {
        type: 'Save',
        buttonOption: {
          iconCss: 'e-icons e-update',
          cssClass: 'e-tbar-btn e-btn',
        },
      },
      {
        type: 'Cancel',
        buttonOption: {
          iconCss: 'e-icons e-cancel-icon',
          cssClass: 'e-tbar-btn e-btn',
        },
      },
    ];
    const editSettings: EditSettingsModel = {
      allowEditing: true,
      allowDeleting: true,
      allowEditOnDblClick: true,
      showDeleteConfirmDialog: true,
    };
    const toolbarOptions: any = [
      {
        id: 'cadd',
        align: 'Left',
        text: 'Add',
      },
      'Delete',
    ];

    return (
      <div className='holiday-grid'>
        <div className='col-6 header'>
          <label className='header-label'>Holidays</label>
        </div>
        {this.state.holidays.length > 0 ? (
          <div className='grid-wrapper'>
            <GridComponent
              dataSource={this.state.holidays}
              className='customGrid'
              editSettings={editSettings}
              toolbar={toolbarOptions}
              ref={(s) => {
                this.grid = s;
              }}
              actionBegin={this.actionBegin.bind(this)}
              toolbarClick={this.toolbarClick.bind(this)}
              height='calc(100vh - 318px)'
            >
              <ColumnsDirective>
                {/* <ColumnDirective
                  type='checkbox'
                  allowSorting={false}
                  allowFiltering={false}
                  width='60'
                ></ColumnDirective> */}
                <ColumnDirective field='name' headerText='Name' />
                <ColumnDirective
                  type='datetime'
                  field='date'
                  headerText='Date'
                  format='dd-MMMM-yyyy'
                  editType='datepickeredit'
                />
                <ColumnDirective
                  // headerText='Manage'
                  width='160'
                  commands={commands}
                />
              </ColumnsDirective>
              <Inject services={[CommandColumn, Edit, Toolbar]} />
            </GridComponent>
          </div>
        ) : null}
        {this.state.showDialog ? (
          <DialogComponent
            id='dlgAddHoliday'
            showCloseIcon={true}
            header={'New Holiday'}
            width={
              this.state.isChecked ? this.importGridWidth : this.defaultWidth
            }
            visible={this.state.showDialog}
            close={() => this.setState({ showDialog: false, isChecked: false })}
            content={this.addHolidayForm.bind(this)}
            isModal={true}
            buttons={this.DialogButtonModel}
          />
        ) : null}
      </div>
    );
  }
}
