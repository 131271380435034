import React from 'react';
import './Favorite.css';
import Common from '../../../Util/Common';

interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

export default class Favorite extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { jsonFavorite: undefined };

  componentDidMount() {
    if (!this.state.jsonFavorite) {
      this.GetFavorite();
    }
  }

  private GetFavorite() {
    let data: any;
    data = [
      {
        id: 'payslips',
        text: 'Payslips',
        iconCss: 'fa-solid fa-badge-dollar',
      },
      {
        id: 'leaveviewer',
        text: 'Leave Viewer',
        iconCss: 'fa-solid fa-check-to-slot',
      },
    ];

    this.setState({ jsonFavorite: data });
  }

  render() {
    const onFavoriteClick = (e: any) => {
      let id: any = e.target.dataset.id;
      if (!id) {
        id = e.target.parentElement.dataset.id;
      }

      switch (id) {
        case 'payslips':
          UpdateDataViewer(this.user.isManager ? 4 : 3, true);
          break;

        case 'leaveviewer':
          UpdateDataViewer(this.user.isManager ? 6 : 5);
          break;

        default:
          break;
      }
    };

    const UpdateDataViewer = (
      tabindex: number,
      removeRemaining: boolean = false
    ) => {
      if (!this.user.employee) {
        //DialogUtility.alert('Sorry, You are not linked with any employee!');
        return;
      }

      let dv: any = this.user.employee;
      dv['name'] =
        this.user.employee.employeecode + ' : ' + this.user.employee.personName;

      dv['rootmenu'] = this.props.rootmenu;
      dv['rootmenu'] = this.props.selectedmenu;
      dv['selectedtabindex'] = removeRemaining ? 0 : tabindex;

      dv['tabset'] = [
        {
          name: 'Personal',
          order: 0,
        },
        {
          name: 'Dossier',
          order: 1,
        },
        {
          name: 'Contracts',
          order: 2,
        },
        {
          id: 'payheads',
          name: 'Pay Heads',
          order: 3,
        },
        {
          name: 'Pay Slips',
          order: 4,
        },
        {
          name: 'Timesheet',
          order: 5,
        },
        {
          name: 'Leaves',
          order: 6,
        },
        {
          name: 'Loans',
          order: 7,
        },
        {
          name: 'Membership',
          order: 8,
        },
        {
          name: 'Bank',
          order: 9,
        },
        {
          name: 'Obligation',
          order: 10,
        },
        {
          name: 'Disciplinary',
          order: 11,
        },
      ];
      if (!this.user.isManager) {
        dv['tabset'].splice(3, 1);
      }
      if (removeRemaining) {
        dv['tabset'] = dv['tabset'].slice(tabindex, tabindex + 1);
      }
      //   this.props.updateJsonDataViewer(
      //     this.props.rootmenu,
      //     this.props.selectedmenu,
      //     dv,
      //     true
      //   );
      this.props.updateJsonDataViewer(dv);
    };

    return (
      <div
        className='e-card e-custom-card mt-4 stickydivprev fadeUp'
        style={{ marginTop: '20px' }}
      >
        <div
          className='e-card-header'
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          Favorites
          <i
            className='ps-2 fa-thin fa-star'
            style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '15px' }}
          ></i>
        </div>
        <div className='e-card-content'>
          {this.state.jsonFavorite &&
            (this.state.jsonFavorite as any).map((x: any) => {
              return (
                <div className='row py-2' key={x.id}>
                  <div className='col-12'>
                    <div
                      className='d-flex align-items-center cursor-pointer'
                      data-id={x.id}
                      onClick={onFavoriteClick}
                    >
                      <div className={`font-22 ${x.iconCss}`}></div>
                      <div className='ps-2'>{x.text}</div>
                    </div>
                  </div>
                </div>
              );
            })}

          {/* <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 6 : 5)
                          }
                        >
                          Apply Leave
                        </a> */}

          <div className='row py-2'>
            <div className='col-12'>
              {/* <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 4 : 3)
                          }
                        >
                          View Payslip
                        </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
