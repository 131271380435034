import * as React from 'react';
import Common from '../../Util/Common';
import {
  CellEditArgs,
  ColumnDirective,
  ColumnModel,
  ColumnsDirective,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Inject,
  PdfExport,
  Sort,
  Toolbar,
  ToolbarItems,
  Group,
  ColumnChooser,
  CommandModel,
  CommandColumn,
  Edit,
} from '@syncfusion/ej2-react-grids';
import LeaveApplication from './LeaveApplication';
import {
  ContextMenuComponent,
  MenuItemModel,
} from '@syncfusion/ej2-react-navigations';
import {
  leavesummary_contextmenu,
  leavesapproval_contextmenu,
  leavebalance_contextmenu,
} from '../../dummydata/xml_data_employee';
import {
  DialogComponent,
  TooltipComponent,
  TooltipEventArgs,
} from '@syncfusion/ej2-react-popups';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { text } from 'stream/consumers';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import LeaveApproval from './LeaveApproval';
import FileUpload from '../FileUpload';
import DialogConfirm from '../Dialogbox/DialogConfirm';
import LeaveCancellation from './LeaveCancellation';
import './Leave.css';
import {
  CheckBoxComponent,
  RadioButtonComponent,
} from '@syncfusion/ej2-react-buttons';
import SelectorSearch from '../Layout/SelectorSearch';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_LEAVESERVICE } = process.env;

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface viewtype {
  viewtypeid: number;
  employees: any;
  leavetypes: any;
  tabname: string;
  objIconCss?: string;
}

export default class LeaveInfoListing extends React.Component<viewtype> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private grid: GridComponent | null;
  private columns: any = [];
  private contextItems: MenuItemModel[] = [];
  private groupOptions: any = undefined;
  public toolbarOptions: any = undefined;
  public editOptions: any = undefined;
  private child: LeaveApplication | any;
  private cntxitems: MenuItemModel[] = [];
  private cMenu: ContextMenuComponent | null = null;
  private sDate: DatePickerComponent | any = undefined;
  private eDate: DatePickerComponent | any = undefined;
  private sText: TextBoxComponent | any = undefined;
  private lList: DropDownListComponent | any = undefined;
  private eList: DropDownListComponent | any = undefined;
  private nText: NumericTextBoxComponent | any = undefined;
  public dialogObj: any;
  private cellSaveRemark: string = '';
  public leavetypedata: any = [];
  private achild: LeaveApproval | any;
  private gridId: string = 'grdleave_';
  private toolTip: TooltipComponent | null = null;
  private LeaveStatus: any = [];
  private applicationId: any = undefined;
  private colsEmp: ColumnModel[];
  private grdBulkEmp: GridComponent | any = null;
  private DdlList: DropDownListComponent | any = undefined;
  private bulknText: NumericTextBoxComponent | any = undefined;
  public radOwExist: RadioButtonComponent | any = null;
  public radDNOwrts: RadioButtonComponent | any = null;
  public selectedleaveTypeid: undefined;
  private skipemployee: boolean = false;
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.LeaveStatus.push(
      this._structure.LeaveApplicationStatus.Pending.Id,
      this._structure.LeaveApplicationStatus.Approved.Id,
      this._structure.LeaveApplicationStatus.Reject.Id,
      this._structure.LeaveApplicationStatus.Cancelled.Id
    );

    this.grid = null;
    this.columns = [];

    this.child = React.createRef();
    this.achild = React.createRef();

    var _result: any;

    if (this.props.tabname === 'summary') {
      parser.parseString(
        leavesummary_contextmenu,
        function (err: any, result: any) {
          _result = JSON.parse(JSON.stringify(result));
        }
      );
    } else if (this.props.tabname === 'approval') {
      parser.parseString(
        leavesapproval_contextmenu,
        function (err: any, result: any) {
          _result = JSON.parse(JSON.stringify(result));
        }
      );
    } else if (this.props.tabname === 'balances') {
      parser.parseString(
        leavebalance_contextmenu,
        function (err: any, result: any) {
          _result = JSON.parse(JSON.stringify(result));
        }
      );
    }
    this.gridId = 'grdleave_' + this.props.tabname + '_' + this.user.id;
    this.cntxitems = Common.GetContextMenuFromXML(_result.ContextMenu.Item);
    Common.ValidateLocalStore(this.gridId);

    this.colsEmp = [
      {
        type: 'checkbox',
        width: '40',
      },
      {
        field: 'employeecode',
        headerText: 'Code',
        width: '80',
      },
      {
        field: 'personName',
        headerText: 'Name',
      },
    ];
  }

  state = {
    datasource: [],
    showDialog: false,
    leaveobject: undefined,
    fulldata: [],
    approvalDialog: false,
    showbalanceDialog: false,
    operationid: 0,
    currentCell: undefined,
    gcolumns: [],
    showUploadDialog: false,
    PromptValue: '',
    showCancelDialog: false,
    cntxItem: [],
    showBulkUploadDialog: false,
    allEmployee: this.props.employees,
    filteredEmployee: [],
    employeeIds: [],
  };

  componentDidMount(): void {
    this.getLocalStorageData();
    if (this.state.datasource.length <= 0) {
      // SUMMARY -- START
      if (this.props.viewtypeid === 1) {
        this.LeaveSummaryData();
      }
      // SUMMARY -- END

      // APPROVAL STATUS -- START
      if (this.props.viewtypeid === 2) {
        this.LeaveSummaryData();
      }
      // APPROVAL STATUS -- END

      // LEAVE BALANCE -- START
      if (this.props.viewtypeid === 3) {
        this.LeaveBalanceData();
      }
      // LEAVE BALANCE -- END
    }
  }

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.operationid === 1) {
          if (this.state.showDialog === true) {
            this.setState({ showDialog: false, operationid: 0 });
          }
        } else if (this.state.operationid === 2) {
          if (this.state.approvalDialog === true) {
            this.setState({ approvalDialog: false, operationid: 0 });
          }
        } else if (this.state.operationid === 3) {
          if (this.state.showbalanceDialog === true) {
            this.setState({ showbalanceDialog: false, operationid: 0 });
          }
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        if (this.state.operationid === 1) {
          // let data: any = this.child.current.GetState();
          // if (this.allocation_hasCode && !data.alloccode) {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please enter code</p>',
          // });
          //   return;
          // }
          // if (!data.allocname) {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please enter name</p>',
          // });
          //   return;
          // }
          // if (
          //   this.allocation_hasCustomCode &&
          //   this.selectednode.isgroup === false &&
          //   !data.alloccustomcode
          // ) {
          // DialogConfirm.showDialog({
          //   content: '<p class="dialog-contain">Please enter custom code</p>',
          // });
          //   return;
          // }
          // let payload: any = null;
          // payload = {
          //   id:
          //     this.selectedmenuid === 'changeitem'
          //       ? this.selectednode!['id']
          //       : null,
          //   code: data.alloccode,
          //   name: data.allocname,
          //   isgroup: this.editingnode.isgroup,
          //   customCode: data.alloccustomcode,
          //   [this.allocation_idfield]:
          //     this.grdAllocation.dataSource.length > 0 &&
          //       this.selectednode &&
          //       this.editingnode.addnew === true
          //       ? this.selectednode!['id']
          //       : this.selectednode && this.selectedmenuid === 'changeitem'
          //         ? this.selectednode![this.allocation_idfield]
          //         : null,
          // };
          // this.SaveAllocation(payload);
        } else if (this.state.operationid === 2) {
          this.ApproveRejectApplication(
            this._structure.LeaveApplicationStatus.Approved.Id,
            ''
          );
        } else if (this.state.operationid === 3) {
          this.AssignBalance();
        }
      },
    },
  ];

  public BulkUploadButton: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => this.setState({ showBulkUploadDialog: false }),
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        this.AddBulkUpload();
      },
    },
  ];

  //new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0, 0)
  processSummaryData = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        // applyDate: new Date(item.applyDate),
        //startDate: new Date(item.startDate),
        //endDate: new Date(item.endDate),
        // approvedStartDate: item.approvedStartDate ? new Date(item.approvedStartDate) : null,
        // approvedEndDate: item.approvedEndDate ? new Date(item.approvedEndDate) : null,
        // joiningDate: new Date(item.applyDate),
        applyDate: new Date(
          new Date(item.applyDate).getFullYear(),
          new Date(item.applyDate).getMonth(),
          new Date(item.applyDate).getDate(),
          0,
          0,
          0,
          0
        ),
        startDate: new Date(
          new Date(item.startDate).getFullYear(),
          new Date(item.startDate).getMonth(),
          new Date(item.startDate).getDate(),
          0,
          0,
          0,
          0
        ),
        endDate: new Date(
          new Date(item.endDate).getFullYear(),
          new Date(item.endDate).getMonth(),
          new Date(item.endDate).getDate(),
          0,
          0,
          0,
          0
        ),
        approvedStartDate: item.approvedStartDate
          ? new Date(
            new Date(item.approvedStartDate).getFullYear(),
            new Date(item.approvedStartDate).getMonth(),
            new Date(item.approvedStartDate).getDate(),
            0,
            0,
            0,
            0
          )
          : null,
        approvedEndDate: item.approvedEndDate
          ? new Date(
            new Date(item.approvedEndDate).getFullYear(),
            new Date(item.approvedEndDate).getMonth(),
            new Date(item.approvedEndDate).getDate(),
            0,
            0,
            0,
            0
          )
          : null,
        joiningDate: new Date(
          new Date(item.joiningDate).getFullYear(),
          new Date(item.joiningDate).getMonth(),
          new Date(item.joiningDate).getDate(),
          0,
          0,
          0,
          0
        ),
      };
    });
  };

  processBalanceData = (data: any) => {
    return data.map((item: any) => {
      return {
        ...item,
        // joiningDate: new Date(item.joiningDate),
        joiningDate: new Date(
          new Date(item.joiningDate).getFullYear(),
          new Date(item.joiningDate).getMonth(),
          new Date(item.joiningDate).getDate(),
          0,
          0,
          0,
          0
        ),
      };
    });
  };

  // getLocalStorageData = () => {
  //   const savedColumns = localStorage.getItem(this.gridId);
  //   if (savedColumns) {
  //     JSON.parse(savedColumns).map((item: any) => {
  //       if (this.columns.filter((x: any) => x.field == item.field).length > 0) {
  //         this.columns.filter((x: any) => x.field == item.field)[0].visible =
  //           item.visible;
  //       }
  //     });
  //   } else {
  //     console.log('No columns data found in localStorage.');
  //   }
  // };

  LeaveApprovalData = () => { };

  LeaveSummaryData = () => {
    Common.ShowSpinner();

    let payload: any = {
      employeeId: this.user.isManager ? null : this.user.employee.id,
    };

    if (this.props.viewtypeid === 2) {
      payload = {
        employeeId: this.user.isManager ? null : this.user.employee.id,
        maxLeveApprover: 2,
        approversWise: true,
      };
    }

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getall${!this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        // if (this.props.viewtypeid === 1) {
        //   if (this.user.isManager) {
        //     this.groupOptions = {
        //       columns: ['employeeName'],
        //       captionTemplate: '<div className="grouphead" style="margin-left: 20px !important;">${key}</div>',
        //       showDropArea: false,

        //     };
        //   }
        //   else {
        //     this.groupOptions = {
        //       columns: ['leaveType.name'],
        //       captionTemplate: '<div className="grouphead" style="margin-left: 20px !important;">${key}</div>',
        //       showDropArea: false,

        //     };
        //   }
        // } else if (this.props.viewtypeid === 2) {
        //   this.groupOptions = {
        //     columns: ['applicationNumber'],
        //     captionTemplate: '<div className="grouphead" style="margin-left: 20px !important;">${key}</div>',
        //     showDropArea: false,
        //     //
        //   };
        // }

        let data: any;

        if (this.props.viewtypeid === 1) {
          data = resopnse.data;
        } else if (this.props.viewtypeid === 2) {
          // data = resopnse.data;

          if (this.user.isManager && this.user.employee) {
            data = resopnse.data.filter(
              (x: any) => x.approverId == this.user.employee.id
            );
          } else {
            data = resopnse.data;
          }
        } else if (this.props.viewtypeid === 3) {
          data = resopnse.data;
        }

        this.contextItems = [
          // { text: 'ExcelExport', id: 'Eexport' },
          // { text: 'ColumnChooser', id: 'Cchooser' },
        ];
        this.setState({ cntxItem: this.contextItems });
        this.toolbarOptions = ['ExcelExport', 'ColumnChooser'];
        if (this.props.viewtypeid === 1) {
          this.columns = [
            {
              field: 'applicationNumber',
              headerText: 'Form No.',
              width: '60',
              visible: true,
              showInColumnChooser: false,
              allowGrouping: true,
            },
            {
              field: 'employeeName',
              headerText: 'Employee',
              width: '100',
              visible: true,
              showInColumnChooser: false,
              allowGrouping: true,
            },
            {
              field: 'leaveType.name',
              headerText: 'Leave Type',
              width: '100',
              visible: true,
              showInColumnChooser: false,
              allowGrouping: true,
            },
            {
              field: 'applyDate',
              headerText: 'Date of Request',
              visible: true,
              showInColumnChooser: true,
              type: 'datetime',
              width: '90',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'startDate',
              headerText: 'Start Date',
              visible: true,
              showInColumnChooser: false,
              type: 'datetime',
              width: '80',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'endDate',
              headerText: 'End Date',
              visible: true,
              showInColumnChooser: false,
              type: 'datetime',
              width: '80',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'appliedDays',
              headerText: 'Requested Days',
              width: '100',
              visible: true,
              showInColumnChooser: true,
              textAlign: 'Right',
              format: 'N2',
              customAttributes: { class: 'customLeaveCss' },
              allowGrouping: false,
            },
            {
              field: 'remark',
              headerText: 'Remark',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              allowGrouping: false,
            },
            {
              field: 'approvedStartDate',
              headerText: 'Approved Start Date',
              type: 'datetime',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'approvedEndDate',
              headerText: 'Approved End Date',
              type: 'datetime',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'approvedDays',
              headerText: 'Approved Days',
              visible: true,
              showInColumnChooser: true,
              width: '80',
              textAlign: 'Right',
              format: 'N2',
              customAttributes: { class: 'customApprovedDayCss' },
              allowGrouping: false,
            },
            {
              field: 'statusName',
              headerText: 'Status',
              visible: true,
              // textAlign: 'end',
              showInColumnChooser: false,
              width: '80',
              allowGrouping: true,
            },
            {
              field: 'Commands',
              textAlign: 'Right',
              showInColumnChooser: false,
              visible: true,
              width: '80',
              allowGrouping: false,
            },
          ];
        } else if (this.props.viewtypeid === 2) {
          this.columns = [
            {
              field: 'applicationNumber',
              headerText: 'Leave Form No.',
              width: '100',
              visible: true,
              showInColumnChooser: false,
              allowGrouping: true,
            },
            {
              field: 'employeeName',
              headerText: 'Employee',
              visible: true,
              showInColumnChooser: false,
              width: '100',

              allowGrouping: true,
            },
            {
              field: 'leaveType.name',
              headerText: 'Leave Type',
              visible: true,
              showInColumnChooser: false,
              width: '100',

              allowGrouping: true,
            },
            {
              field: 'applyDate',
              headerText: 'Date of Request',
              visible: true,
              showInColumnChooser: true,
              type: 'datetime',
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'startDate',
              headerText: 'Start Date',
              visible: true,
              showInColumnChooser: true,
              type: 'datetime',
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'endDate',
              headerText: 'End Date',
              visible: true,
              showInColumnChooser: true,
              type: 'datetime',
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
            },
            {
              field: 'appliedDays',
              headerText: 'Requested Days',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              textAlign: 'Right',
              format: 'N2',
              customAttributes: { class: 'customLeaveCss' },
              allowGrouping: false,
            },
            {
              field: 'remark',
              headerText: 'Remark',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              allowGrouping: false,
            },
            {
              field: 'approvedStartDate',
              headerText: 'Approved Start Date',
              visible: true,
              type: 'datetime',
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
              showInColumnChooser: true,
            },
            {
              field: 'approvedEndDate',
              headerText: 'Approved End Date',
              visible: true,
              type: 'datetime',
              width: '100',
              format: `${this.user.settings.formats.DateShort}`,
              enableGroupByFormat: true,
              allowGrouping: false,
              showInColumnChooser: true,
            },
            {
              field: 'approvedDays',
              headerText: 'Approved Days',
              visible: true,
              showInColumnChooser: true,
              width: '100',
              textAlign: 'Right',
              format: 'N2',
              customAttributes: { class: 'customApprovedDayCss' },
              allowGrouping: false,
            },
            {
              field: 'statusName',
              headerText: 'Status',
              visible: true,
              showInColumnChooser: false,
              width: '100',

              allowGrouping: true,
            },
            {
              field: 'approverName',
              headerText: 'Approver',
              visible: true,
              showInColumnChooser: true,
              width: '100',

              allowGrouping: true,
            },
            {
              field: 'Commands',
              textAlign: 'Right',
              visible: true,
              showInColumnChooser: false,
              width: '100',
              allowGrouping: false,
            },
          ];
        }
        data = this.processSummaryData(data);

        if (this.props.viewtypeid === 2) {
          if (this.user.isManager == true) {
            let currlevel = 0;
            if (
              data
                .flatMap((item: any) =>
                  item.leaveApproval.flatMap(
                    (approval: any) => approval.leaveApprovers
                  )
                )
                .filter((x: any) => x.employeeId == this.user.employee.id)
                .length > 0
            ) {
              currlevel = data
                .flatMap((item: any) =>
                  item.leaveApproval.flatMap(
                    (approval: any) => approval.leaveApprovers
                  )
                )
                .filter(
                  (x: any) => x.employeeId == this.user.employee.id
                )[0].level;
            }

            data = data.filter((x: any) => x.isApplicationVisible == true);

            if (currlevel > 1) {
              data = data.filter(
                (x: any) =>
                  x.lastApprStatusId ===
                  this._structure.LeaveApplicationStatus.Approved.Id
              );
            }
          }
        }

        data = data.reduce(function (a: any, d: any) {
          if (a.filter((x: any) => x.id === d.id).length <= 0) {
            a.push(d);
          }
          return a;
        }, []);

        this.getLocalStorageData();

        this.setState({
          datasource: data,
          fulldata: resopnse.data,
          gcolumns: this.columns,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  };

  LeaveBalanceData = () => {
    Common.ShowSpinner();

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getbalance${!this.user.isManager ? '/ess' : ''
      }`,
      { employeeId: this.user.isManager ? null : this.user.employee.id },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        // if (this.user.isManager) {
        //   if (response.data[0].employeeName !== null) {
        //     this.groupOptions = {
        //       columns: ['employeeName'],
        //       captionTemplate: '<div className="grouphead" style="margin-left: 20px !important;">${key}</div>',
        //       showDropArea: false,
        //       //
        //     };
        //   }
        // }
        // else {
        //   this.groupOptions = {
        //     columns: ['leaveType.name'],
        //     captionTemplate: '<div className="grouphead" style="margin-left: 20px !important;">${key}</div>',
        //     showDropArea: false,
        //     //
        //   };
        // }

        this.toolbarOptions = [
          'ExcelExport',
          'ColumnChooser',
          {
            id: 'moreoption',
            align: 'Right',
            text: 'Assign Entitlement',
          },
          { id: 'uploadbal', align: 'Right', text: 'Import Balances' },
        ];

        this.contextItems = [
          // { text: 'ExcelExport', id: 'Eexport' },
          // { text: 'ColumnChooser', id: 'Cchooser' },
          { id: 'moreoption', text: 'Assign Entitlement' },
          { id: 'uploadbal', text: 'Import Balances' },
          { id: 'bulkupload', text: 'Bulk Assign Entitlement' },
        ];

        this.setState({ cntxItem: this.contextItems });

        this.editOptions = {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: true,
          mode: 'Batch',
        };

        this.columns = [
          {
            field: 'employeeName',
            headerText: 'Employee',
            width: '100',
            visible: true,
            showInColumnChooser: false,
            allowGrouping: true,
          },
          {
            field: 'joiningDate',
            headerText: 'Joining Date',
            width: '100',
            type: 'datetime',
            format: `${this.user.settings.formats.DateShort}`,
            visible: true,
            showInColumnChooser: true,
            enableGroupByFormat: true,
            allowGrouping: false,
          },
          {
            field: 'leaveType.name',
            headerText: 'Leave Type',
            visible: true,
            showInColumnChooser: false,
            width: '100',

            allowGrouping: true,
          },
          {
            field: 'totalEntitledAmount',
            headerText: 'Yearly Entitlement',
            visible: true,
            showInColumnChooser: false,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            editType: 'numericedit',
            customAttributes: { class: 'customLeaveBalancesCss' },
            allowGrouping: true,
          },
          {
            field: 'leaveBroughtForward',
            headerText: 'Leave BF',
            visible: true,
            showInColumnChooser: true,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            customAttributes: { class: 'customLeaveBalancesCss' },
            allowGrouping: false,
          },
          {
            field: 'entitledToDate',
            headerText: 'Accrued to Date',
            visible: true,
            showInColumnChooser: false,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            customAttributes: { class: 'customLeaveBalancesCss' },
            allowGrouping: false,
          },
          {
            field: 'issueAmount',
            headerText: 'Taken To Date',
            visible: true,
            showInColumnChooser: false,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            customAttributes: { class: 'customLeaveBalancesCss' },
            allowGrouping: false,
          },
          {
            field: 'leaveBalanceAdjustment.adjustmentAmount',
            headerText: 'Adjustment',
            visible: this.user.isManager ? true : false,
            showInColumnChooser: false,
            width: '100',
            //textAlign: 'Right',
            format: 'N2',
            editType: 'numericedit',
            allowGrouping: false,
          },
          {
            field: 'totalAdjustedAmount',
            headerText: 'Adjusted Amount',
            visible: true,
            showInColumnChooser: true,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            editType: 'numericedit',
            customAttributes: { class: 'customLeaveBalancesAdjustedCss' },
            allowGrouping: false,
          },
          {
            field: 'availableToDate',
            headerText: 'Available to Date',
            visible: true,
            showInColumnChooser: false,
            width: '100',
            textAlign: 'Right',
            format: 'N2',
            customAttributes: { class: 'customLeaveAvailableCss' },
            allowGrouping: false,
          },
          {
            field: 'Commands',
            textAlign: 'Right',
            showInColumnChooser: false,
            visible: true,
            width: '80',
            allowGrouping: false,
          },
        ];
        let data = this.processBalanceData(response.data);

        this.getLocalStorageData();

        this.setState({
          datasource: data,
          fulldata: response.data,
          gcolumns: this.columns,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  };

  generateReport = (eappid: any) => {
    Common.ShowSpinner();

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/leaveform`,
      {
        id: eappid,
        maxLeveApprover: 2,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (response.length > 0) {
          let a: any = window.open('', '');

          a.document.write(
            Common.GetHTMLReportHeaderStart({
              reportTitle: 'Leave Application',
              companyName: this.user.tenantName,
            })
          );
          a.document.write(Common.GetHTMLReportHeaderEnd());
          a.document.write(response);
          a.document.write(Common.GetHTMLReportFooter());
          a.document.close();

          //a.print();
        } else {
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  };

  public Filter: any = {
    type: 'Excel',
  };

  public toolbarClick(args: any): void {
    switch (args.item.id) {
      case this.gridId + '_pdfexport':
        this.grid?.pdfExport();
        break;
      case this.gridId + '_excelexport':
        this.grid?.excelExport();
        break;
      case 'grid-lvleavedata_csvexport':
        this.grid?.csvExport();
        break;
      case 'moreoption':
        this.setState({ showbalanceDialog: true, operationid: 3 });
        break;
      case 'uploadbal':
        this.setState({ showUploadDialog: true });
        break;
    }
  }

  private handleCntxBeforeOpen(args: any) {
    const target = args?.event?.target;
    if (target?.closest('.e-frozencontent.e-frozen-left-content') === null) {
      args.cancel = true;
    }
  }

  private handleCntxSelect(args: any) {
    if (args.item?.id === 'moreoption') {
      this.setState({ showbalanceDialog: true, operationid: 3 });
    } else if (args.item.id === 'uploadbal') {
      this.setState({ showUploadDialog: true });
    } else if (args.item.id === 'bulkupload') {
      this.setState({ showBulkUploadDialog: true });
    }
  }

  private handletoolbarCntxSelect(args: any) {
    if (args.target.id === 'Eexport') {
      this.grid?.excelExport();
    } else if (args.target.id === 'Cchooser') {
      this.grid?.openColumnChooser();
    }
  }

  public toolbarTemplate() {
    return (
      <div
        className='e-toolbar-items'
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          padding: '10px 0px',
          gap: '20px',
        }}
      >
        {this.props.tabname == 'balances' ?
          <div className='e-toolbar-item'>
            <span
              id='resetcycle'
              onClick={this.handletoolbarCntxSelect.bind(this)}
              style={{
                fontSize: '15px',
                cursor: 'pointer',
                textTransform: 'capitalize',
                padding: '10px',
                borderRadius: '4px',
                fontWeight: 400,
                boxShadow: 'none',
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
              className='e-tbar-btn e-btn fa-regular fa-circle-calendar'
              title='End Leave Cycle'
            ></span>
          </div> : ''}
        <div className='e-toolbar-item'>
          <span
            id='Eexport'
            onClick={this.handletoolbarCntxSelect.bind(this)}
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              textTransform: 'capitalize',
              padding: '10px',
              borderRadius: '4px',
              fontWeight: 400,
              boxShadow: 'none',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
            className='e-tbar-btn e-btn fa-regular fa-download'
          ></span>
        </div>
        <div className='e-toolbar-item'>
          <span
            className='e-tbar-btn e-btn'
            id='Cchooser'
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              textTransform: 'capitalize',
              padding: '10px',
              borderRadius: '4px',
              fontWeight: 400,
              boxShadow: 'none',
              border: '1px solid rgba(0, 0, 0, 0.12)',
            }}
            onClick={this.handletoolbarCntxSelect.bind(this)}
          >
            Show/Hide Columns
          </span>
        </div>

        <div
          className='e-toolbar-item'
          style={{ padding: '5px', paddingRight: '10px' }}
        >
          {this.contextItems.length > 0 ? (
            <i
              id='target'
              onClick={this.btnCntxMenuClick}
              className='e-icons e-more-vertical-1'
              style={{ cursor: 'pointer' }}
            ></i>
          ) : (
            <i></i>
          )}
        </div>
        {this.contextItems.length > 0 ? (
          <ContextMenuComponent
            id={Math.random().toString() + 'cntxItem'}
            ref={(sc) => (this.cMenu = sc as ContextMenuComponent)}
            items={this.contextItems}
            target='#target'
            beforeOpen={this.handleCntxBeforeOpen.bind(this)}
            select={this.handleCntxSelect.bind(this)}
          />
        ) : null}
      </div>
    );
  }

  public cntxOpen(e: any) {
    if (e.rowInfo.rowData) {
      for (let i = 0; i < e.items.length; i++) {
        let item: string = e.items[i].properties.text;
        this.grid?.contextMenuModule.contextMenu.showItems([item]);
      }
      if (this.props.tabname === 'summary') {
        if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Pending.Id
        ) {
          let item: string = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '300'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
        } else if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Approved.Id
        ) {
          let item: string = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '100'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '200'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
        } else if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Reject.Id ||
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Cancelled.Id
        ) {
          let item: string = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '100'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '200'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '300'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '400'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
        }
      } else if (this.props.tabname === 'approval') {
        if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Pending.Id
        ) {
          let item: string = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '600'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
        } else if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Approved.Id ||
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Reject.Id ||
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Cancelled.Id
        ) {
          let item: string = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '100'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '200'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '300'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '400'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
          item = (
            Object.values(e.items).filter(
              (x: any) => x.properties.id == '600'
            )[0] as any
          ).properties.text;
          this.grid?.contextMenuModule.contextMenu.hideItems([item]);
        }
      } else if (this.props.tabname === 'balances') {
      }
    } else {
      e.cancel = true;
    }
  }

  public cntxClick(e: any) {
    if (this.props.tabname === 'summary') {
      if (e.rowInfo && e.rowInfo.rowData) {
        if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Pending.Id
        ) {
          if (e.item.properties.id == '100') {
            this.setState({
              showDialog: true,
              leaveobject: e.rowInfo.rowData,
              operationid: 1,
            });
          }
          if (e.item.properties.id == '200') {
            if (e.rowInfo && e.rowInfo.rowData) {
              this.applicationId = e.rowInfo.rowData.id;
              DialogConfirm.showDialog({
                isConfirm: true,
                content:
                  '<p class="dialog-contain">Are you sure you want to permanently delete this leave request? This action cannot be undone.</p>',
                okCaption: 'Accept',
                closeCaption: 'Cancel',
                showCloseIcon: true,
                OkEvent: this.confirmDelete.bind(this),
              });
            }
          }
        }
        if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Approved.Id
        ) {
          if (e.item.properties.id == '300') {
            if (e.rowInfo && e.rowInfo.rowData) {
              this.setState({
                showCancelDialog: true,
                leaveobject: e.rowInfo.rowData,
                operationid: 1,
              });
            }
          }
        }

        if (e.item.properties.id == '500') {
          this.generateReport(e.rowInfo.rowData.id);
        }
      }
    } else if (this.props.tabname === 'approval') {
      if (e.rowInfo && e.rowInfo.rowData) {
        if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Pending.Id
        ) {
          if (e.item.properties.id == '100') {
            this.setState({
              showDialog: true,
              leaveobject: e.rowInfo.rowData,
              operationid: 1,
            });
          } else if (e.item.properties.id == '200') {
            if (this.state.fulldata.length > 0) {
              let _data = this.getDistinctData(
                this.state.fulldata
                  .flatMap((i: any) =>
                    i.leaveApproval.flatMap((a: any) => a.leaveApprovers)
                  )
                  .filter((x) => x.leaveApplicationId == e.rowInfo.rowData.id)
              );
              if (_data && this.user.employee) {
                let level: number = _data.filter(
                  (x: any) => x.employeeId == this.user.employee.id
                )[0].level;
                // if (level > 0 && _data.filter((x: any) => x.level < level && x.statusId == this._structure.LeaveApplicationStatus.Approved.Id).length <= 0) {
                //   DialogUtility.alert('Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.');
                //   return;
                // }
                // else {

                // }

                if (
                  level > 0 &&
                  _data.filter(
                    (x: any) =>
                      x.statusId ==
                      this._structure.LeaveApplicationStatus.Pending.Id &&
                      x.level < level
                  ).length > 0
                ) {
                  // DialogConfirm.showDialog({
                  //   content:
                  //     '<p class="dialog-contain">Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.</p>',
                  // });
                  this.toastObj = ToastUtility.show({
                    cssClass: 'e-toast-warning',
                    icon: 'e-warning toast-icons',
                    showCloseButton: true,
                    content:
                      'Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.',
                    target: '#basic_card',
                    position: { X: 'Center', Y: 'Top' },
                    width: 'auto',
                    timeOut: 3000,
                  });
                  return;
                } else {
                  this.setState({
                    approvalDialog: true,
                    isapprovalmode: true,
                    leaveobject: e.rowInfo.rowData,
                    operationid: 2,
                  });
                }
              }
            }
          } else if (e.item.properties.id == '300') {
            if (this.state.fulldata.length > 0) {
              let _data = this.getDistinctData(
                this.state.fulldata
                  .flatMap((i: any) =>
                    i.leaveApproval.flatMap((a: any) => a.leaveApprovers)
                  )
                  .filter((x) => x.leaveApplicationId == e.rowInfo.rowData.id)
              );
              if (_data && this.user.employee) {
                let level: number = _data.filter(
                  (x: any) => x.employeeId == this.user.employee.id
                )[0].level;

                // if (level > 0 && _data.filter((x: any) => x.level < level).length > 0 && _data.filter((x: any) => x.statusId == this._structure.LeaveApplicationStatus.Pending.Id).length > 0) {
                //   DialogUtility.alert('Sorry, you cannot reject the selected leave application. Reason, previous approver has not take any action on it.');
                //   return;
                // }
                if (
                  level > 0 &&
                  _data.filter(
                    (x: any) =>
                      x.statusId ==
                      this._structure.LeaveApplicationStatus.Pending.Id &&
                      x.level < level
                  ).length > 0
                ) {
                  // DialogConfirm.showDialog({
                  //   content:
                  //     '<p class="dialog-contain">Sorry, you cannot reject the selected leave application. Reason, previous approver has not take any action on it.</p>',
                  // });
                  this.toastObj = ToastUtility.show({
                    cssClass: 'e-toast-warning',
                    icon: 'e-warning toast-icons',
                    showCloseButton: true,
                    content:
                      'Sorry, you cannot reject the selected leave application. Reason, previous approver has not take any action on it.',
                    target: '#basic_card',
                    position: { X: 'Center', Y: 'Top' },
                    width: 'auto',
                    timeOut: 3000,
                  });
                  return;
                } else {
                  this.setState({ leaveobject: e.rowInfo.rowData });
                  DialogConfirm.showDialog({
                    isConfirm: true,
                    isPrompt: true,
                    onPromptInput: (value) => {
                      console.log('Prompt input value:', value);
                      this.setState({
                        PromptValue: value,
                      });
                    },
                    showCloseIcon: true,
                    content:
                      '<p class="dialog-contain">Enter reason for rejection: </p>',
                    // '< input id= "inputEle" type="text" name="inputEle" class= "e-input" placeholder="Type reason here.." multiline={ true} /> ',
                    okCaption: 'Yes',
                    closeCaption: 'No',
                    OkEvent: this.confirmAction.bind(this),
                  });
                }
              }
            }
          }
        } else if (
          e.rowInfo.rowData.statusId ===
          this._structure.LeaveApplicationStatus.Approved.Id
        ) {
          if (e.item.properties.id == '600') {
            this.setState({
              showCancelDialog: true,
              leaveobject: e.rowInfo.rowData,
              operationid: 1,
            });
          }
        }
        if (e.item.properties.id == '500') {
          this.generateReport(e.rowInfo.rowData.id);
        }
      }
    }
  }

  btnCntxMenuClick = (args: any) => {
    this.cMenu?.open(args.clientY, args.clientX);
  };

  confirmAction = () => {
    this.ApproveRejectApplication(
      this._structure.LeaveApplicationStatus.Reject.Id,
      this.state.PromptValue
    );
  };

  confirmDelete = () => {
    if (this.applicationId != undefined) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/delete`,
        {
          id: this.applicationId,
        },
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          if (response === true) {
            let data = this.state.datasource.filter(
              (x: any) => x.id !== this.applicationId
            );
            this.applicationId = undefined;
            this.setState({ datasource: data });
          }
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
            // });
          }
        })
        .finally(() => { });
    }
  };

  getPreviousLevel(data: any, currentLevel: number) {
    const levels = data.map((item: any) => item.level);
    const filteredLevels = levels.filter((l: any) => l < currentLevel);
    return filteredLevels.length ? Math.max(...filteredLevels) : -1;
  }

  getDistinctData = (data: any) => {
    const employeeMap: any = {};
    const filteredData = data.filter((obj: any) => {
      const employeeId = obj.employeeId;
      if (!employeeMap[employeeId]) {
        employeeMap[employeeId] = true;
        return true;
      }
      return false;
    });
    return filteredData;
  };

  private datechange(e: any) {
    if (
      e.target.id.toLowerCase() === 'startdate' ||
      e.target.id.toLowerCase() === 'enddate'
    ) {
      let _range = Common.getDatesInRange(
        new Date((document.getElementById('startdate') as any).value),
        new Date((document.getElementById('enddate') as any).value)
      );
      (document.getElementById('lvapproveddays') as any).value = _range.length;
    }

    if (e.target.id.toLowerCase() === 'startdate') {
      this.eDate.min = new Date(
        (document.getElementById('startdate') as any).value
      );
    }
  }

  private dialogContent() {
    let _apprdays = 0;
    if ((this.state.leaveobject as any)!.approvedDays <= 0) {
      let _range = Common.getDatesInRange(
        new Date((this.state.leaveobject as any)!.startDate),
        new Date((this.state.leaveobject as any)!.endDate)
      );
      _apprdays = _range.length;
    }

    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Application Number
            </label>
            <input
              className='input-text-border'
              id='lvformnumber'
              name='lvformnumber'
              type='text'
              autoComplete='off'
              style={{ fontSize: '15px', backgroundColor: 'transparent' }}
              value={(this.state.leaveobject as any)!.applicationNumber}
              disabled
            />
          </div>
          <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Leave Type
            </label>
            <input
              className='input-text-border'
              id='lvleavetype'
              name='lvleavetype'
              type='text'
              autoComplete='off'
              style={{ fontSize: '15px', backgroundColor: 'transparent' }}
              value={(this.state.leaveobject as any)!.leaveType.name}
              disabled
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Employee
            </label>
            <input
              className='input-text-border'
              id='lvemployee'
              name='lvemployee'
              type='text'
              autoComplete='off'
              style={{ fontSize: '15px', backgroundColor: 'transparent' }}
              value={(this.state.leaveobject as any)!.employeeName}
              disabled
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-5 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Start Date
            </label>
            <DatePickerComponent
              id='startdate'
              ref={(scope) => {
                this.sDate = scope;
              }}
              format={this.user.settings.formats.DateShort}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              value={new Date((this.state.leaveobject as any)!.startDate)}
              onChange={this.datechange.bind(this)}
            />
          </div>
          <div className='col-5 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              End Date
            </label>
            <DatePickerComponent
              id='enddate'
              ref={(scope) => {
                this.eDate = scope;
              }}
              format={this.user.settings.formats.DateShort}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              value={new Date((this.state.leaveobject as any)!.endDate)}
              onChange={this.datechange.bind(this)}
            />
          </div>
          <div className='col-2 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Approved Days
            </label>
            <input
              className='input-text-border'
              id='lvapproveddays'
              name='lvapproveddays'
              type='text'
              autoComplete='off'
              style={{ fontSize: '15px', backgroundColor: 'transparent' }}
              value={
                (this.state.leaveobject as any)!.approvedDays > 0
                  ? (this.state.leaveobject as any)!.approvedDays
                  : _apprdays
              }
              disabled
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Remark
            </label>
            <TextBoxComponent
              id='lvremark'
              multiline={true}
              ref={(scope) => {
                this.sText = scope;
              }}
            ></TextBoxComponent>
          </div>
        </div>
      </>
    );

    return <>{_content}</>;
  }

  private AssignBalance() {
    const inputs = [
      document.querySelector<HTMLInputElement>('#employee'),
      document.querySelector<HTMLInputElement>('#leaveid'),
    ];
    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');
        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
    if (this.nText.value == null || this.nText.value <= 0) {
      const TargetInput =
        document.querySelector<HTMLInputElement>('#entileamt');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, Entitlement amount is mandatory information. Please amount to continue.</p>',
      // });
      // this.toastObj = ToastUtility.show({

      //   cssClass: 'e-toast-warning',
      //   icon: 'e-warning toast-icons',
      //   showCloseButton: true,
      //   content:
      //     'Sorry, Entitlement amount is mandatory information. Please amount to continue.',
      //   target: '#basic_card',
      //   position: { X: 'Center', Y: 'Top' },
      //   timeOut: 3000,
      // width:'auto',
      // });
      return;
    }
    if (this.eList.itemData && this.lList.itemData && this.nText.value) {
      let payload: any = {
        employeeId: this.eList.itemData.id,
        leaveTypeId: this.lList.itemData.id,
        startDate: Common.formatDate(
          new Date(new Date().getFullYear(), 0, 1),
          'yyyy-MM-dd'
        ),
        endDate: Common.formatDate(
          new Date(new Date().getFullYear(), 11, 31),
          'yyyy-MM-dd'
        ),
        entitledAmount: this.nText.value,
        leaveBroughtForward: 0,
        maxCarryForwardAmount: 0,
        issueAmount: 0,
      };
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/addbalance`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          this.LeaveBalanceData();
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: 'Yearly Entitlement Amount Assigned Successfully..!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          this.setState({ showbalanceDialog: false, operationid: 0 });
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
            // });
            // this.toastObj = ToastUtility.show({
            //   cssClass: 'e-toast-error',
            //   icon: 'far fa-circle-xmark',
            //   showCloseButton: true,
            //   content: `<p>${error.response.data.messages.join()}</p>`,
            //   target: '#basic_card',
            //   position: { X: 'Center', Y: 'Top' },
            //   timeOut: 3000,
            // width:'auto',
            // });
          }
        })
        .finally(() => { });
    }
  }

  private confirmAssignment() {
    let elem = document.getElementById('bulkentileamt');
    let employeeIds = this.grdBulkEmp
      .getSelectedRecords()
      .map((item: any) => item.id);
    let payload: any = {
      empIds: employeeIds,
      leaveTypeId: this.DdlList.itemData.id,
      entitledAmount: (elem as any).ej2_instances[0].value,
      startDate: Common.formatDate(
        new Date(new Date().getFullYear(), 0, 1),
        'yyyy-MM-dd'
      ),
      endDate: Common.formatDate(
        new Date(new Date().getFullYear(), 11, 31),
        'yyyy-MM-dd'
      ),
      leaveBroughtForward: 0,
      maxCarryForwardAmount: 0,
      issueAmount: 0,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/addbalance`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.LeaveBalanceData();
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
          // });
        }
      })
      .finally(() => { });
  }

  private AddBulkUpload() {
    if (this.selectedleaveTypeid == undefined) {
      let TargetDdl: any = document.getElementById('leavetypeid');
      if (TargetDdl) {
        TargetDdl.style.borderBottom = '2px solid red';
      }
    }
    if (this.bulknText.value == null || this.bulknText.value <= 0) {
      let TargetNText: any = document.getElementById('bulkentileamt');
      if (TargetNText) {
        TargetNText.style.borderBottom = '2px solid red';
      }
    }
    if (this.grdBulkEmp.getSelectedRowIndexes().length <= 0) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Please select atleast one employee.</p>',
      // });
      (document.getElementById('errorMessage') as HTMLElement).innerText =
        'Please select atleast one employee.';
      // this.toastObj = ToastUtility.show({

      //   cssClass: 'e-toast-warning',
      //   icon: 'e-warning toast-icons',
      //   showCloseButton: true,
      //   content: 'Please select atleast one employee.',
      //   target: '#basic_card',
      //   position: { X: 'Center', Y: 'Top' },
      //   timeOut: 3000,
      // width:'auto',
      // });
      return;
    }

    if (this.radOwExist.disabled == false) {
      if (
        this.radDNOwrts.checked == false &&
        this.radOwExist.checked == false
      ) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Please set atleast one operation to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please set atleast one operation to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
    }
    if (this.selectedleaveTypeid && this.bulknText.value > 0) {
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain">Are you sure you want to assigned the selected leavetype to the checked employee(s)?</p></div>',
        okCaption: 'Yes',
        closeCaption: 'No',
        OkEvent: this.confirmAssignment.bind(this),
      });
    }
  }

  private ApproveRejectApplication(statusid: number, remark: string) {
    let payload: any;
    let final = false;
    let ilevel = 0;
    if (
      this.state.fulldata
        .flatMap((item: any) =>
          item.leaveApproval.flatMap((approval: any) => approval.leaveApprovers)
        )
        .filter(
          (x) =>
            x.leaveApplicationId == (this.state.leaveobject as any)!.id &&
            x.employeeId == this.user.employee.id
        )
    ) {
      final = this.state.fulldata
        .flatMap((item: any) =>
          item.leaveApproval.flatMap((approval: any) => approval.leaveApprovers)
        )
        .filter(
          (x) =>
            x.leaveApplicationId == (this.state.leaveobject as any)!.id &&
            x.employeeId == this.user.employee.id
        )[0].isFinal;
      ilevel = this.state.fulldata
        .flatMap((item: any) =>
          item.leaveApproval.flatMap((approval: any) => approval.leaveApprovers)
        )
        .filter(
          (x) =>
            x.leaveApplicationId == (this.state.leaveobject as any).id &&
            x.employeeId == this.user.employee.id
        )[0].level;
    }

    if (statusid == this._structure.LeaveApplicationStatus.Approved.Id) {
      // if (this.sText.value == null) {
      //   DialogUtility.alert('Sorry, Remark is mandatory information. Please provide remark to continue.');
      //   return;
      // }

      let dateRange: any = [];
      let sRange = Common.getDatesInRange(this.sDate.value, this.eDate.value);
      for (let i = 0; i < sRange.length; i++) {
        dateRange.push({
          leaveApplicationId: (this.state.leaveobject as any)!.id,
          appliedDate: Common.formatDate(
            Common.RemoveTimezoneOffsetFromDate(new Date(sRange[i])),
            'yyyy-MM-dd'
          ),
          dayFraction: 1,
          leaveApprovalId: this.user.employee.id,
        });
      }

      let apprDays = Common.getDatesInRange(this.sDate.value, this.eDate.value);

      payload = {
        leaveApplicationId: (this.state.leaveobject as any)!.id,
        employeeId: this.user.employee.id,
        approvalDate: new Date(),
        approvalRemark: this.sText.value,
        approvedStartDate: Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(this.sDate.value),
          'yyyy-MM-dd'
        ),
        approvedEndDate: Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(this.eDate.value),
          'yyyy-MM-dd'
        ),
        approvedDays: apprDays.length,
        statusId: statusid,
        isFinal: final,
        leaveAppliedDays: dateRange,
        level: ilevel,
      };
    } else if (statusid == this._structure.LeaveApplicationStatus.Reject.Id) {
      payload = {
        leaveApplicationId: (this.state.leaveobject as any)!.id,
        employeeId: this.user.employee.id,
        approvalDate: new Date(),
        approvalRemark: remark,
        approvedStartDate: null,
        approvedEndDate: null,
        approvedDays: 0,
        statusId: statusid,
        isFinal: true,
        leaveAppliedDays: null,
        // leaveAppliedDays: (this.state.leaveobject as any)!.leaveAppliedDays,
        level: ilevel,
      };
    }

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/approveleave`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let Selectedleave: any = this.state.leaveobject;
        if (Selectedleave) {
          let strMsg: any = '';
          strMsg = `Leave request for <b>${Selectedleave.employeeName}</b> with <b>${Selectedleave.applicationNumber}</b> has been declined successfully.`;
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${strMsg}</p>`,
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: strMsg,
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
        }
        this.LeaveSummaryData();
        this.setState({ approvalDialog: false, operationid: 0 });
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join(
          //     '<br>'
          //   )}</p>`,
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-error',
            icon: 'far fa-circle-xmark',
            showCloseButton: true,
            content: `<p>${error.response.data.messages.join('<br>')}</p>`,
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
        }
      })
      .finally(() => { });
  }

  private onchangeevt(e: any) {
    if (e.target.id == 'employee') {
      if (e.target.itemData) {
        this.leavetypedata = this.props.leavetypes.filter(
          (x: any) =>
            (x.genderId == null ||
              x.genderId == e.target.itemData.personGenderId) &&
            x.isAccrued == true
        );
        this.lList.dataSource = this.leavetypedata;
      }
    } else if (e.target.id == 'leaveid') {
      if (e.target.itemData) {
        if (document.getElementById('lvAccrualType')) {
          (document.getElementById('lvAccrualType') as any).value =
            e.target.itemData.accrualTypeName;
        }
        if (e.target.itemData.isAccrued === true) {
          if (document.getElementById('entileamt')) {
            if (
              e.target.itemData.accrualTypeId ==
              this._structure.AccrualType.Monthly.id
            ) {
              (
                document.getElementById('entileamt') as any
              ).ej2_instances[0].value = Math.round(
                e.target.itemData.accruedValue * 12
              );
            } else if (
              e.target.itemData.accrualTypeId ==
              this._structure.AccrualType.Yearly.id
            ) {
              (
                document.getElementById('entileamt') as any
              ).ej2_instances[0].value = e.target.itemData.accruedValue;
            }
          }
        }
      }
    }
  }

  private FetchFilteredEmp(leaveType: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/getbalance${!this.user.isManager ? '/ess' : ''
      }`,
      {
        employeeId: this.user.isManager ? null : this.user.employee.id,
        leaveTypeId: leaveType.id,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        let employeeId = data.map((obj: any) => obj.employeeId);
        this.state.filteredEmployee = this.state.allEmployee.filter(
          (item: any) => {
            if (leaveType.gender === 'Female') {
              return (
                !employeeId.includes(item.id) &&
                (leaveType.genderId == null ||
                  item.personGenderId === leaveType.genderId)
              );
            } else {
              return !employeeId.includes(item.id);
            }
          }
        );
        this.grdBulkEmp.dataSource = this.state.filteredEmployee;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => { });
  }

  private OnCheckChange(e: any) {
    if (e.target.id !== undefined && e.target.id === 'chkskipemployee') {
      this.skipemployee = e.target.element.ej2_instances[0].checked;
    } else {
      if (e.target.element.ej2_instances[0].checked === true) {
        this.grdBulkEmp.dataSource = this.state.allEmployee;
        this.radOwExist.disabled = false;
      } else if (e.target.element.ej2_instances[0].checked === false) {
        this.grdBulkEmp.dataSource = this.state.filteredEmployee;
        this.radOwExist.disabled = true;
      }
    }
  }

  private OnGridCheckChange(e: any) {
    if (document.getElementById('lblSelectedEmpCount')) {
      (document.getElementById('lblSelectedEmpCount') as any).value =
        'Total Selected Employee : ' +
        this.grdBulkEmp.getSelectedRowIndexes().length;
    }
  }

  private onchangeBulkUpload(args: any) {
    let TargetDDl: any = document.getElementById('leavetypeid');
    let TargetNText: any = document.getElementById('bulkentileamt');
    if (args.target.itemData != null) {
      this.FetchFilteredEmp(args.target.itemData);
      if (args.target.itemData.isAccrued === true) {
        if (TargetNText) {
          if (
            args.target.itemData.accrualTypeId ==
            this._structure.AccrualType.Monthly.id
          ) {
            TargetNText.ej2_instances[0].value = Math.round(
              args.target.itemData.accruedValue * 12
            );
            TargetNText.style.borderBottom = 'none';
          } else if (
            args.target.itemData.accrualTypeId ==
            this._structure.AccrualType.Yearly.id
          ) {
            TargetNText.ej2_instances[0].value =
              args.target.itemData.accruedValue;
            TargetNText.style.borderBottom = 'none';
          } else {
            TargetNText.ej2_instances[0].value = 0;
            TargetNText.style.borderBottom = '2px solid red';
          }
        }
      }
      this.selectedleaveTypeid = args.target.itemData.id;
      if (this.selectedleaveTypeid) {
        if (TargetDDl) {
          TargetDDl.style.borderBottom = 'none';
        }
      }
    }
  }

  private onChangeEntitleMent(args: any) {
    let TargetNText: any = document.getElementById('bulkentileamt');
    if (args.value > 0) {
      if (TargetNText) {
        TargetNText.style.borderBottom = 'none';
      }
    }
  }

  private balanceContent() {
    this.leavetypedata = this.props.leavetypes.filter(
      (x: any) => x.isAccrued == true
    );
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Employee
            </label>
            <span className='mandatoryinfo'>*</span> */}
            <DropDownListComponent
              id='employee'
              dataSource={this.props.employees}
              filterBarPlaceholder='Search a Employee'
              allowFiltering={true}
              filterType='Contains'
              fields={{ value: 'id', text: 'personName' }}
              placeholder='Select Employee *'
              floatLabelType='Auto'
              popupHeight='220px'
              ref={(scope) => {
                this.eList = scope;
              }}
              onChange={this.onchangeevt.bind(this)}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Leave Type
            </label>
            <span className='mandatoryinfo'>*</span> */}
            <DropDownListComponent
              id='leaveid'
              dataSource={this.leavetypedata}
              filterBarPlaceholder='Search a Leave'
              allowFiltering={true}
              ref={(scope) => {
                this.lList = scope;
              }}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Leave *'
              floatLabelType='Auto'
              popupHeight='220px'
              onChange={this.onchangeevt.bind(this)}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          {/* <div className='col-6 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Accrual Type
            </label>
            <input
              className='input-text-border'
              id='lvAccrualType'
              name='lvbalance'
              type='text'
              autoComplete='off'
              style={{ fontSize: '15px', backgroundColor: 'transparent' }}
              readOnly
              value={''}
              disabled
            />
          </div> */}
          <div className='col-12 py-2'>
            {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Yearly Entitlement Amount
            </label>
            <span className='mandatoryinfo'>*</span> */}
            <NumericTextBoxComponent
              id='entileamt'
              cssClass='numerictext-right'
              placeholder='Enter Yearly Entitlement Amount *'
              floatLabelType='Auto'
              value={0}
              decimals={2}
              ref={(scope) => {
                this.nText = scope;
              }}
            />
          </div>
        </div>
      </>
    );
    return _content;
  }

  private bulkUploadContent() {
    this.leavetypedata = this.props.leavetypes.filter(
      (x: any) => x.isAccrued == true
    );
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Leave Type
            </label> */}
            <DropDownListComponent
              id='leavetypeid'
              name='leavetypeid'
              dataSource={this.leavetypedata}
              filterBarPlaceholder='Search a Leave'
              allowFiltering={true}
              ref={(scope) => {
                this.DdlList = scope;
              }}
              filterType='Contains'
              fields={{ value: 'id', text: 'name' }}
              placeholder='Select Leave *'
              floatLabelType='Auto'
              popupHeight='220px'
              onChange={this.onchangeBulkUpload.bind(this)}
            />
          </div>
          <div className='col-6 py-2'>
            {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Yearly Entitlement Amount
            </label> */}
            <NumericTextBoxComponent
              id='bulkentileamt'
              cssClass='numerictext-right'
              placeholder='Yearly Entitlement Amount'
              floatLabelType='Auto'
              value={0}
              decimals={2}
              min={0}
              ref={(scope) => {
                this.bulknText = scope;
              }}
              onChange={this.onChangeEntitleMent.bind(this)}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2' style={{ display: 'none' }}>
            <RadioButtonComponent
              id='radOwExist'
              ref={(c: any) => (this.radOwExist = c)}
              label='Overwrite Existing'
              name='radblukopr'
              value='1'
              disabled={true}
            ></RadioButtonComponent>
          </div>
          <div className='col-6 py-2' style={{ display: 'none' }}>
            <RadioButtonComponent
              id='radDNOwrts'
              ref={(c: any) => (this.radDNOwrts = c)}
              label='Do not Overwrite'
              name='radblukopr'
              value='2'
              checked={true}
            ></RadioButtonComponent>
          </div>
        </div>
        <div className='row align-items-center'>
          <div
            className='col-7 py-2'
            style={{ marginLeft: '10px', display: 'none' }}
          >
            <CheckBoxComponent
              checked={false}
              label={'Include assigned employee(s)'}
              onChange={this.OnCheckChange.bind(this)}
            ></CheckBoxComponent>
          </div>
          <div className='col-4 py-2'>
            <input
              type='label'
              id='lblSelectedEmpCount'
              value={'Total Selected Employee : 0'}
              style={{
                border: '0px',
                cursor: 'none',
                width: '95%',
                backgroundColor: 'transparent',
              }}
              disabled
            ></input>
          </div>
          <div className='col-12 py-2'>
            <GridComponent
              id='leaveTypeemployeelist-grid'
              className='leaveTypeemployeelist-grid-class'
              height={'calc(100vh - 500px)'}
              width={'100%'}
              dataSource={undefined}
              columns={this.colsEmp}
              ref={(obj: any) => (this.grdBulkEmp = obj)}
              checkBoxChange={this.OnGridCheckChange.bind(this)}
              rowSelected={this.OnGridCheckChange.bind(this)}
              rowDeselected={this.OnGridCheckChange.bind(this)}
            />
            <SelectorSearch
              grid_id={'leaveTypeemployeelist-grid'}
              showMoreOptions={false}
            />
          </div>
        </div>
      </>
    );
    return _content;
  }

  // private cellSave(e: any) {
  //   if (e.value == null || e.value <= 0) {
  //     DialogUtility.alert('Sorry, amount is mandatory information. Please amount to continue.');
  //     return;
  //   }

  //   if (this.cellSaveRemark == undefined) {
  //     // DialogUtility.confirm({
  //     //   title: 'Aruti',
  //     //   content:
  //     //     '<p>Enter reason for rejection: </p><input id= "inputEle" type="text" name="inputEle" class="e-input" placeholder="Type reason here.." multiline={true} />',
  //     //   okButton: { text: 'Yes', click: this.confirmAction.bind(this) },
  //     //   cancelButton: {},
  //     // });

  //     DialogUtility.alert('Sorry, amount is mandatory information. Please amount to continue.');
  //     return;

  //   }
  //   // this.setState({ currentCell: e });

  //   // this.dialogObj = DialogUtility.confirm({
  //   //   title: 'Aruti',
  //   //   content:
  //   //     '<p>Enter reason for rejection: </p><input id= "inputEle" type="text" name="inputEle" class="e-input" placeholder="Type reason here.." multiline={true} />',
  //   //   okButton: { text: 'Yes', click: this.confirmAction.bind(this) },
  //   //   cancelButton: {},
  //   // });
  // }

  // if (e.rowData) {
  //   let entAmount: number = 0;
  //   let adjAmount: number = 0;
  //   let adjustpayload: any = [];
  //   if (e.columnName?.toLowerCase() == 'entitledamount') {
  //     entAmount = e.value;
  //   }
  //   else if (e.columnName?.toLowerCase() == 'leavebalanceadjustment.adjustmentamount') {
  //     adjAmount = e.value;
  //     adjustpayload.push({
  //       leaveBalanceId: e.rowData.Id,
  //       adjustmentRemark: "string",
  //       adjustmentAmount: adjAmount,
  //       leaveBFAdjustmentAmount: 0
  //     })
  //   }
  //   let payload: any = {
  //     id: e.rowData.Id,
  //     employeeId: e.rowData.employeeId,
  //     leaveTypeId: e.rowData.leaveTypeId,
  //     startDate: Common.formatDate(new Date(e.rowData.startDate), 'yyyy-MM-dd'),
  //     endDate: Common.formatDate(new Date(e.rowData.endDate), 'yyyy-MM-dd'),
  //     entitledAmount: entAmount,
  //     leaveBroughtForward: 0,
  //     maxCarryForwardAmount: 0,
  //     issueAmount: 0,
  //     leaveBalanceAdjustment: adjustpayload
  //   }
  //   Common.ApiCallAsync(
  //     'POST',
  //     `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/addbalance`,
  //     payload,
  //     Common.getToken() || '',
  //     null,
  //     this.token.tenant
  //   )
  //     .then((response: any) => {
  //       return response.data;
  //     })
  //     .then((response: any) => {
  //       this.LeaveBalanceData()
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //       if (
  //         error.response &&
  //         error.response.data &&
  //         error.response.data.messages &&
  //         error.response.data.messages.length > 0
  //       ) {
  //         DialogUtility.alert(error.response.data.messages.join());
  //       }
  //     })
  //     .finally(() => { });
  // }

  private rowDataBound(e: any) {
    if (e.data && e.data.employeeId === null) {
      (this.grid!.getRowByIndex(0) as any).style.display = 'none';
    }
  }

  private queryCellInfo(e: any) {
    if (
      e.column?.field.toLowerCase() ===
      'leavebalanceadjustment.adjustmentamount'
    ) {
      // (e.cell as any).textContent = 'Enter Adjustment Amount';
      // (e.cell as any).style.color = 'grey';
      e.cell.classList.add('cellborder');
    }
  }

  private cellSave = (e: any) => {
    if (e.columnName.toLowerCase() === 'totalentitledamount') {
      if (e.value == null) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, amount is mandatory information. Please amount to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, amount is mandatory information. Please amount to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }

      if (e.value <= 0) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, amount is mandatory information. Please amount to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, amount is mandatory information. Please amount to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
    } else if (
      e.columnName.toLowerCase() === 'leavebalanceadjustment.adjustmentamount'
    ) {
      if (e.value == null) {
        (e.cell as any).textContent = 'Enter Adjustment Amount';
        (e.cell as any).style.color = 'grey';
        return;
      }

      if (e.value == 0) {
        // DialogConfirm.showDialog({
        //   content:
        //     '<p class="dialog-contain">Sorry, amount is mandatory information. Please amount to continue.</p>',
        // });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content:
            'Sorry, amount is mandatory information. Please amount to continue.',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        return;
      }
    }

    this.setState({ currentCell: e });
    e.cancel = true;
    if (e.columnName.toLowerCase() === 'totalentitledamount') {
      DialogConfirm.showDialog({
        isConfirm: true,
        content:
          '<div class="row" style="display: flex;flex-direction: column;align-items: center;"><p class="dialog-contain" style="font-size:16px; font-weight: 100 !important;">Are you sure you want to update entitled amount?</p></div>',
        okCaption: 'Yes',
        CloseEvent: () => {
          this.grid!.closeEdit();
        },
        showCloseIcon: true,
        OkEvent: this.confirm.bind(this),
      });
    } else if (
      e.columnName.toLowerCase() === 'leavebalanceadjustment.adjustmentamount'
    ) {
      DialogConfirm.showDialog({
        isConfirm: true,
        isPrompt: true,
        onPromptInput: (value) => {
          console.log('Prompt input value:', value);
          this.setState({
            PromptValue: value,
          });
        },
        showCloseIcon: true,
        content: '<p class="dialog-contain">Enter reason for Adjustment: </p>',
        okCaption: 'Ok',
        CloseEvent: () => {
          this.grid!.closeEdit();
        },
        OkEvent: this.confirm.bind(this),
      });
    }
  };

  private confirm() {
    if (this.state.currentCell !== undefined) {
      let e = this.state.currentCell;
      if (
        (e as any).columnName.toLowerCase() ===
        'leavebalanceadjustment.adjustmentamount'
      ) {
        this.updateBalance(e, this.state.PromptValue);
      } else if (
        (e as any).columnName.toLowerCase() === 'totalentitledamount'
      ) {
        this.updateBalance(e, '');
      }
    }
  }

  private updateBalance(data: any, remark: string) {
    if (this.dialogObj) {
      this.dialogObj.hide();
    }
    let entAmount: number = 0;
    let adjAmount: number = 0;
    let adjustpayload: any = [];
    if ((data as any).columnName.toLowerCase() == 'totalentitledamount') {
      entAmount = (data as any).value;
    } else if (
      (data as any).columnName.toLowerCase() ==
      'leavebalanceadjustment.adjustmentamount'
    ) {
      adjAmount = (data as any).value;
      adjustpayload.push({
        leaveBalanceId: data.rowData.id,
        adjustmentRemark: remark,
        adjustmentAmount: adjAmount,
        leaveBFAdjustmentAmount: 0,
      });
    }

    let payload: any = {
      id: data.rowData.id,
      employeeId: data.rowData.employeeId,
      leaveTypeId: data.rowData.leaveTypeId,
      startDate: Common.formatDate(
        new Date(data.rowData.startDate),
        'yyyy-MM-dd'
      ),
      endDate: Common.formatDate(new Date(data.rowData.endDate), 'yyyy-MM-dd'),
      entitledAmount:
        entAmount <= 0 ? data.rowData.totalEntitledAmount : entAmount,
      leaveBroughtForward: 0,
      maxCarryForwardAmount: 0,
      issueAmount: 0,
      leaveBalanceAdjustment: adjustpayload,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/addbalance`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        this.LeaveBalanceData();
        this.setState({ currentCell: undefined });
      })
      .catch((error: any) => {
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.messages &&
          error.response.data.messages.length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
          // });
        }
      })
      .finally(() => { });
  }

  handleSave = (data: any) => {
    if (data !== undefined) {
      let strMsg: any = '';
      strMsg =
        'Leave request (' +
        data.leaveType.name +
        ') for ' +
        Common.formatDate(new Date(data.startDate), 'MMM dd, yyyy') +
        ' to ' +
        Common.formatDate(new Date(data.endDate), 'MMM dd, yyyy') +
        ' has been successfully edited. You will be notified once your request is approved.';
      // DialogConfirm.showDialog({
      //   content: `<p class="dialog-contain">${strMsg}</p>`,
      // });
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-success',
        icon: 'far fa-circle-check',
        showCloseButton: true,
        content: strMsg,
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });

      this.setState({ showDialog: false, operationid: 0 });
      this.LeaveSummaryData();
    }
  };

  handleCancelLeave = (data: any) => {
    if (data !== undefined) {
      this.setState({ showCancelDialog: false, operationid: 0 });
      this.LeaveSummaryData();
    }
  };

  handleApprSave = (data: any) => {
    let employeeName: any = document.querySelectorAll(
      '#dlgApplicationApproval_dialog-content #lvemployee'
    )[0];
    let applicationNo: any = document.querySelectorAll(
      '#dlgApplicationApproval_dialog-content #lvformnumber'
    )[0];
    if (data !== undefined) {
      let strMsg: any = '';
      strMsg = `Leave request for <b>${employeeName.value}</b> with <b>${applicationNo.value}</b> has been approved successfully.`;
      // DialogConfirm.showDialog({
      //   content: `<p class="dialog-contain">${strMsg}</p>`,
      // });
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-success',
        icon: 'far fa-circle-check',
        showCloseButton: true,
        content: strMsg,
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });
      this.setState({ approvalDialog: false, operationid: 0 });
      this.LeaveSummaryData();
    }
  };

  public uploadbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        this.handleuploadclose();
      },
    },
  ];

  public generateuploadcontent() {
    var content: any;
    content = (
      <>
        <div className='row align-items-center'>
          <FileUpload uploadtypeid={this._structure.UploadType.LeaveBalance} />
        </div>
      </>
    );
    return content;
  }

  DeleteApplication(e: any) {
    console.log('e', e);
    this.applicationId = e.id;
    DialogConfirm.showDialog({
      isConfirm: true,
      content:
        '<p class="dialog-contain">Are you sure you want to permanently delete this leave request? This action cannot be undone.</p>',
      okCaption: 'Accept',
      closeCaption: 'Cancel',
      showCloseIcon: true,
      OkEvent: this.confirmDelete.bind(this),
    });
  }

  DeleteLeaveBalance(e: any) {
    let payload: any = {
      id: e.id,
      employeeId: e.employeeId,
      leaveTypeId: e.leaveTypeId,
    };
    DialogConfirm.showDialog({
      isConfirm: true,
      content:
        '<p class="dialog-contain">You are about to remove the assigned balance for the selected employee and leave type. Are you sure you want to proceed with delete operaton?</p>',
      okCaption: 'Accept',
      closeCaption: 'Cancel',
      showCloseIcon: true,
      OkEvent: () => {
        Common.ApiCallAsync(
          'POST',
          `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/voidbalance`,
          payload,
          Common.getToken() || '',
          null,
          this.token.tenant
        )
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            if (response === true) {
              let data = this.state.datasource.filter(
                (x: any) => x.id !== payload.id
              );
              this.setState({ datasource: data });
            }
          })
          .catch((error: any) => {
            console.error(error);
            if (
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              error.response.data.messages.length > 0
            ) {
              // DialogConfirm.showDialog({
              //   content: `<p class="dialog-contain">${error.response.data.messages.join()}</p>`,
              // });
            }
          })
          .finally(() => { });
      },
    });
  }
  cellTemplate(args: any) {
    return (
      <div>
        {args.statusId == 1 ? ( // APPROVED
          <div id='status' className='estatus approvedcolor'>
            <span className='estatustxt approvedcolor'>{args.statusName}</span>
          </div>
        ) : args.statusId == 2 ? ( // Pending
          <div id='status' className='estatus pendingcolor'>
            <span className='estatustxt pendingcolor'>{args.statusName}</span>
          </div>
        ) : args.statusId == 3 ? ( // Rejected
          <div id='status' className='estatus rejectedcolor'>
            <span className='estatustxt rejectedcolor'>{args.statusName}</span>
          </div>
        ) : args.statusId == 6 ? ( // Cancelled
          <div id='status' className='estatus cancelcolor'>
            <span className='estatustxt cancelcolor'>{args.statusName}</span>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
  handleuploadclose = () => {
    this.LeaveBalanceData();
    this.setState({ showUploadDialog: false });
  };

  // updateColumnVisibility = () => {
  //   localStorage.setItem(this.gridId, JSON.stringify(this.state.gcolumns));
  // };

  updateColumnVisibility = () => {
    console.log('Updating column visibility', this.state.gcolumns);

    if (this.state.gcolumns && this.state.gcolumns.length > 0) {
      const hasValidData = this.state.gcolumns.some((col: any) => col.field);

      if (hasValidData) {
        const existingColumns = localStorage.getItem(this.gridId);

        if (
          !existingColumns ||
          existingColumns !== JSON.stringify(this.state.gcolumns)
        ) {
          localStorage.setItem(
            this.gridId,
            JSON.stringify(this.state.gcolumns)
          );
          console.log('Column visibility updated in local storage.');
        } else {
          console.log('Column visibility already up to date in local storage.');
        }
      } else {
        console.log('Invalid column data, not updating local storage.');
      }
    } else {
      console.log('No column data to update.');
    }
  };

  handleColumnChooserChange = (args: any) => {
    if (args['columns'] !== undefined) {
      const updatedColumns = args.columns.map((col: any) => ({
        ...col,
        visible: col.visible,
      }));

      updatedColumns.forEach((item: any) => {
        const column = this.columns.find((x: any) => x.field == item.field);
        if (column) {
          column.visible = item.visible;
        }
      });

      this.setState({ gcolumns: this.columns }, this.updateColumnVisibility);
    }
  };

  handleColumnsDragStart = (args: any) => {
    if (this.columns.length > 0) {
      this.columns
        .filter((x: any) => !x['allowGrouping'])
        .map((e: any) => {
          if (
            e.field == args.column.field &&
            e.allowGrouping == args.column.allowGrouping
          ) {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Grouping is disabled for this column.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: 'Grouping is disabled for this column.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }
        });
    }
  };

  getLocalStorageData = () => {
    const savedColumns = localStorage.getItem(this.gridId);
    if (savedColumns) {
      JSON.parse(savedColumns).forEach((item: any) => {
        const column = this.columns.find((x: any) => x.field == item.field);
        if (column) {
          column.visible = item.visible;
        }
      });
    } else {
      console.log('No columns data found in localStorage.');
    }
  };

  handleInfo(e: any) {
    this.generateReport(e.id);
  }
  handleReject(e: any) {
    this.setState({ leaveobject: e });
    DialogConfirm.showDialog({
      isConfirm: true,
      isPrompt: true,
      showCloseIcon: true,
      onPromptInput: (value: any) => {
        console.log('Prompt input value:', value);
        this.setState({
          PromptValue: value,
        });
      },
      content: '<p class="dialog-contain">Enter reason for rejection: </p>',
      // '< input id= "inputEle" type="text" name="inputEle" class= "e-input" placeholder="Type reason here.." multiline={ true} /> ',
      okCaption: 'Yes',
      closeCaption: 'No',
      OkEvent: this.confirmAction.bind(this),
    });
  }
  ApproveCommand(e: any) {
    if (this.state.fulldata.length > 0) {
      let _data = this.getDistinctData(
        this.state.fulldata
          .flatMap((i: any) =>
            i.leaveApproval.flatMap((a: any) => a.leaveApprovers)
          )
          .filter((x) => x.leaveApplicationId == e.id)
      );
      if (_data && this.user.employee) {
        let level: number = _data.filter(
          (x: any) => x.employeeId == this.user.employee.id
        )[0].level;
        // if (level > 0 && _data.filter((x: any) => x.level < level && x.statusId == this._structure.LeaveApplicationStatus.Approved.Id).length <= 0) {
        //   DialogUtility.alert('Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.');
        //   return;
        // }
        // else {

        // }

        if (
          level > 0 &&
          _data.filter(
            (x: any) =>
              x.statusId == this._structure.LeaveApplicationStatus.Pending.Id &&
              x.level < level
          ).length > 0
        ) {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content:
              'Sorry, you cannot approve the selected leave application. Reason, previous approver has not take any action on it.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        } else {
          this.setState({
            approvalDialog: true,
            isapprovalmode: true,
            leaveobject: e,
            operationid: 2,
          });
        }
      }
    }
  }
  commandTemplate = (args: any) => {
    // console.log('first args.statusId == 1', args);
    return (
      <div className='command-buttons'>
        {args.statusId == 3 && (
          <>
            <button
              className='e-flat info-btn'
              onClick={() => this.handleInfo(args)}
              title='Info'
            >
              <i className='fa fa-info'></i>
            </button>
          </>
        )}
        {args.statusId == 2 && (
          <>
            <button
              className='e-flat pen-btn'
              onClick={() =>
                this.setState({
                  showDialog: true,
                  leaveobject: args,
                  operationid: 1,
                })
              }
              title='Edit'
            >
              <i className='fa fa-pencil' aria-hidden='true'></i>{' '}
            </button>
            {this.props.tabname == 'summary' ? (
              <>
                <button
                  className='e-flat trash-btn'
                  onClick={() => this.DeleteApplication(args)}
                  title='Delete'
                >
                  <i className='fa-solid fa-trash'></i>
                </button>
              </>
            ) : (
              <>
                <button
                  className='e-flat approve-btn'
                  onClick={() => this.ApproveCommand(args)}
                  title='Approve'
                >
                  <i className='fas fa-check'></i>
                </button>
                <button
                  className='e-flat reject-btn'
                  onClick={() => this.handleReject(args)}
                  title='Decline'
                >
                  <i className='fas fa-times'></i>
                </button>
              </>
            )}
            <button
              className='e-flat info-btn'
              onClick={() => this.handleInfo(args)}
              title='Info'
            >
              <i className='fa fa-info'></i>
            </button>
          </>
        )}
        {args.statusId == 6 && (
          <>
            <button
              className='e-flat info-btn'
              onClick={() => this.handleInfo(args)}
              title='Info'
            >
              <i className='fa fa-info'></i>
            </button>
          </>
        )}
        {args.statusId == 1 && (
          <>
            {this.props.tabname === 'summary' ? (
              <>
                <button
                  className='e-flat cancel-btn'
                  onClick={() =>
                    this.setState({
                      showCancelDialog: true,
                      leaveobject: args,
                      operationid: 1,
                    })
                  }
                  title='Cancel'
                >
                  <i className='fas fa-ban'></i>
                </button>
              </>
            ) : (
              ''
            )}
            <button
              className='e-flat info-btn'
              onClick={() => this.handleInfo(args)}
              title='Info'
            >
              <i className='fa fa-info'></i>
            </button>
          </>
        )}
        {this.props.tabname === 'balances' ? (
          <>
            <button
              className='e-flat trash-btn'
              onClick={() => this.DeleteLeaveBalance(args)}
              title='Delete'
            >
              <i className='fa-solid fa-trash'></i>
            </button>
          </>
        ) : (
          ''
        )}
      </div>
    );
  };
  beforeRender = (args: TooltipEventArgs) => {
    const fieldName = args.target.getAttribute('aria-label') || '';
    const tooltipFields = ['Remark', 'Employee', 'Approver'];

    // Check if the fieldName contains any of the tooltipFields
    const isMatchingField = tooltipFields.some((field) =>
      fieldName.includes(field)
    );
    if (isMatchingField) {
      if (args.target.innerText) {
        (this.toolTip as TooltipComponent).content = args.target.innerText;
      } else {
        args.cancel = true;
      }
    } else {
      args.cancel = true;
    }
  };

  render() {
    // const childGrid: GridModel = {
    //   columns: [
    //     {
    //       field: 'leaveApproval.leaveApprovers.employee.personName',
    //       headerText: 'Approver',
    //       allowEditing: false
    //     },
    //     {
    //       field: 'leaveApproval.leaveApprovers.approvalDate',
    //       headerText: 'Approved Date',
    //       allowEditing: false
    //     },
    //     {
    //       field: 'leaveApproval.leaveApprovers.approvedStartDate',
    //       headerText: 'Approve Start Date',
    //       allowEditing: false
    //     },
    //     {
    //       field: 'leaveApproval.leaveApprovers.approvedEndDate',
    //       headerText: 'Approve End Date',
    //       allowEditing: false
    //     },
    //     {
    //       field: 'leaveApproval.leaveApprovers.approvedDays',
    //       headerText: 'Approved Days',
    //       allowEditing: false
    //     },
    //     {
    //       field: 'leaveApproval.leaveApprovers.statusName',
    //       headerText: 'Status',
    //       allowEditing: false
    //     },
    //   ],
    // }

    // const detailDataBound = (args: any) => {
    //   let orderData = this.state.datasource;
    //   let IdValue = args.childGrid.parentDetails.parentRowData.id;
    //   let matchedData = new DataManager((orderData as any).leaveApproval).executeLocal(
    //     new Query().where('leaveApplicationId', 'equal', IdValue, true)
    //   );
    //   args.childGrid.query = new Query();
    //   args.childGrid.dataSource = matchedData;
    // };

    const cellEdit = (args: CellEditArgs) => {
      if (this.user.isManager === false) {
        args.cancel = true;
      } else {
        if (
          args.columnName?.toLowerCase() !== 'totalentitledamount' &&
          args.columnName?.toLowerCase() !==
          'leavebalanceadjustment.adjustmentamount'
        ) {
          args.cancel = true;
        }
      }
    };

    const handleCreated = () => {
      this.grid?.on('columnDragStart', this.handleColumnsDragStart, this);
    };

    return (
      <>
        <div id='divleavetypes' className='py-2'>
          <div className={this.state.datasource.length > 0 ? '' : ''}>
            <TooltipComponent
              id='Tooltip'
              target='.e-rowcell'
              beforeRender={this.beforeRender}
              cssClass='custom-tooltip'
              ref={(t) => (this.toolTip = t)}
            >
              <GridComponent
                id={this.gridId}
                // id={'grdleave_' + this.props.tabname}
                showColumnChooser={true}
                enablePersistence={true}
                ref={(g) => (this.grid = g)}
                height='calc(100vh - 425px)'
                dataSource={this.state.datasource}
                // columns={this.columns}
                allowFiltering={true}
                allowSorting={true}
                filterSettings={this.Filter}
                allowGrouping={true}
                // groupSettings={this.groupOptions}
                toolbar={this.toolbarOptions}
                editSettings={this.editOptions}
                allowExcelExport={true}
                allowPdfExport={true}
                //toolbarClick={this.toolbarClick.bind(this)}
                toolbarTemplate={this.toolbarTemplate.bind(this)}
                contextMenuItems={this.cntxitems}
                contextMenuOpen={this.cntxOpen.bind(this)}
                contextMenuClick={this.cntxClick.bind(this)}
                actionComplete={this.handleColumnChooserChange}
                cellEdit={this.props.viewtypeid === 3 ? cellEdit : undefined}
                cellSave={
                  this.props.viewtypeid === 3 ? this.cellSave : undefined
                }
                rowDataBound={
                  this.props.viewtypeid === 3 ? this.rowDataBound : undefined
                }
                queryCellInfo={
                  this.props.viewtypeid === 3 ? this.queryCellInfo : undefined
                }
                // allowTextWrap={true}
                // rowHeight={25}
                // pageSettings={{ pageSize: 25 }}
                enableInfiniteScrolling={true}
                created={handleCreated}
              >
                <ColumnsDirective>
                  {this.state.gcolumns.length > 0 &&
                    this.state.gcolumns.map((col: any, index: any) =>
                      col.field.toLowerCase() === 'statusname' ? (
                        <ColumnDirective
                          key={index}
                          {...col}
                          template={this.cellTemplate}
                        />
                      ) : col.field.toLowerCase() == 'commands' ? (
                        <ColumnDirective
                          key={index}
                          {...col}
                          headerText='Commands'
                          // commands={commandEvent1}
                          template={this.commandTemplate.bind(this)}
                          allowFiltering={false}
                        />
                      ) : (
                        <ColumnDirective key={index} {...col} />
                      )
                    )}
                </ColumnsDirective>
                <Inject
                  services={[
                    Toolbar,
                    PdfExport,
                    ExcelExport,
                    Filter,
                    Sort,
                    DetailRow,
                    ColumnChooser,
                    CommandColumn,
                    Edit,
                  ]}
                />
              </GridComponent>
            </TooltipComponent>
          </div>
        </div>
        {this.state.showDialog ? (
          <DialogComponent
            id='dlgEditApplication'
            showCloseIcon={true}
            header={'Edit Application'}
            width={'40vw'}
            className='dlgClassRes'
            visible={this.state.showDialog}
            close={() => this.setState({ showDialog: false, operationid: 0 })}
            content={() => (
              <LeaveApplication
                leaveobj={this.state.leaveobject}
                onSave={this.handleSave}
                onCancel={() =>
                  this.setState({ showDialog: false, operationid: 0 })
                }
                ref={this.child}
              ></LeaveApplication>
            )}
            isModal={true}
            buttons={[{}]}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.approvalDialog ? (
          <DialogComponent
            id='dlgApplicationApproval'
            showCloseIcon={true}
            className='dlgClassRes'
            header={'Approve Application'}
            width={'40vw'}
            visible={this.state.approvalDialog}
            close={() =>
              this.setState({ approvalDialog: false, operationid: 0 })
            }
            content={() => (
              <LeaveApproval
                leaveobject={this.state.leaveobject}
                onSave={this.handleApprSave}
                onCancel={() =>
                  this.setState({ approvalDialog: false, operationid: 0 })
                }
                ref={this.child}
                statusid={this._structure.LeaveApplicationStatus.Approved.Id}
              ></LeaveApproval>
            )}
            isModal={true}
            buttons={[{}]}
            // content={this.dialogContent.bind(this)}
            // buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showCancelDialog ? (
          <DialogComponent
            id='dlgCancelDialog'
            className='dlgClassRes'
            showCloseIcon={true}
            header={'Cancel Leave Application'}
            width={'60vw'}
            visible={this.state.showCancelDialog}
            close={() =>
              this.setState({ showCancelDialog: false, operationid: 0 })
            }
            content={() => (
              <LeaveCancellation
                leaveobj={this.state.leaveobject}
                onSave={this.handleCancelLeave}
                onCancel={() =>
                  this.setState({ showCancelDialog: false, operationid: 0 })
                }
              ></LeaveCancellation>
            )}
            isModal={true}
            buttons={[{}]}
            // content={this.dialogContent.bind(this)}
            // buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showbalanceDialog ? (
          <DialogComponent
            id='dlgbaalancedialog'
            showCloseIcon={true}
            header={'Assign Yearly Entitlement'}
            width={'25vw'}
            visible={this.state.showbalanceDialog}
            close={() => this.setState({ showbalanceDialog: false })}
            content={this.balanceContent.bind(this)}
            isModal={true}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showUploadDialog ? (
          <DialogComponent
            id='dlguploadeddialog'
            className='dlgClassRes'
            showCloseIcon={true}
            header={'Upload Balances for Current Year'}
            width={'50vw'}
            visible={this.state.showUploadDialog}
            close={() => this.handleuploadclose}
            content={this.generateuploadcontent.bind(this)}
            isModal={true}
            buttons={this.uploadbuttons}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.showBulkUploadDialog ? (
          <DialogComponent
            id='dlgbulkuploadeddialog'
            className='dlgClassRes'
            showCloseIcon={true}
            header={'Bulk Assignment'}
            width={'50vw'}
            visible={this.state.showBulkUploadDialog}
            close={() => this.setState({ showBulkUploadDialog: false })}
            content={this.bulkUploadContent.bind(this)}
            isModal={true}
            buttons={this.BulkUploadButton}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
