// * ---------------------------------------------------------------------------------------------------------------2.Initialize
// * SUHAIL - 2023-02-28 - 10
// * SUHAIL - 2023-03-01 - 10
// * SUHAIL - 2023-02-28 - 20

import React, { Component } from 'react';

import * as AllIonicons from 'react-ionicons';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import Common from '../Util/Common';
import { MenuComponent } from '@syncfusion/ej2-react-navigations';

// * ---------------------------------------------------------------------------------------------------------------3.Style

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

const {
  REACT_APP_ENDPOINT_API,
  REACT_APP_ENDPOINT_SUBSCRIBER,
  REACT_APP_ENDPOINT_SUBSCRIBERPATH,
  REACT_APP_ENDPOINT_XMLPATH,
  REACT_APP_ENDPOINT_SIDEBARPATH,
  REACT_APP_ENDPOINT_FULLJSONPATH,
  REACT_APP_ENDPOINT_TOKENS,
  REACT_APP_ENDPOINT_USERSERVICE,
} = process.env;

interface Item {
  id?: string;
  text: string;
  iconCss?: string;
  href?: string;
  separator?: boolean;
  items?: [];
}

interface Items {
  item: Item[];
  open: boolean;
  mainIcon: string;
  handleSelect?: any;
}

class DPButton extends React.Component<
  Items,
  { dopen: boolean; mainIcon: string }
> {
  private user: any;
  private token: any = null;

  // * ----------------------------------------------------------------------------------------------------------------2.Initialize Component
  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    // * ------------------------------------------------------------------------------------------------------------------5.State
    this.state = {
      dopen: false,
      mainIcon: '',
    };

    this.handleClick = this.handleClick.bind(this);
  }

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter
  // * SUHAIL - 2023-03-06 - 90
  createItems() {
    return this.props.item.map(function (item, index) {
      return {
        id: item.id,
        text: item.text,
        iconCss: item.iconCss,
        url: item.href,
        separator: item.separator,
        items: item.items,
      };
    });
  }

  // * --------------------------------------------------------------------------------------------------------------------Event Handler
  // * SUHAIL - 2023-03-06 - 30
  handleClick() {
    //this.setState({ dopen: !this.state.dopen })
    this.setState({
      dopen: Boolean(
        document
          .getElementsByClassName('userdropdowntoggle')[0]
          .getAttribute('aria-expanded')
      ),
    });
  }

  // * ------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-02-28 - 180
  // * SUHAIL - 2023-03-01 - 30
  // * SUHAIL - 2023-03-06 - 180
  // * FABIO  - 2023-03-16 - 270
  // * DIMITRI - 2023-03-16 - 270
  // * SUHAIL - 2023-03-30 - 120
  // * SUHAIL - 2023-04-28 - 60
  render() {
    var items = this.createItems();
    let ddb: any;
    //var cMainIcon: any = (<AllIonicons['PersonCircleSharp'] />)
    /*const cMainIcon= ({ name }) => {
      const renderedIcon = AllIonicons[name];
      if (!renderedIcon) return <p>Icon not found!</p>;
      return <renderedIcon />;
    };*/

    const onSelect = (args: any) => {
      if (args.item.text === 'Logout') {
        Common.removeUserSession();
        window.location.href = '/';
      } else if (args.item.id === 'changePassword') {
        if (this.props.handleSelect) this.props.handleSelect(args);
      } else if (args.item.parentObj.id === 'switchCompany') {
        let requestdata: any = {
          email: this.user.email,
          newTenantId: args.item.id,
          token: Common.getToken() || '',
        };
        Common.ApiCallAsync(
          'post',
          '' + REACT_APP_ENDPOINT_TOKENS + '/switchtoken',
          requestdata,
          '',
          null,
          args.item.id
        )
          .then((response: any) => {
            let token: string = response.data.token;
            let refreshtoken: string = response.data.refreshToken;
            Common.ApiCallAsync(
              'post',
              `${REACT_APP_ENDPOINT_USERSERVICE}/getuser`,
              { email: this.user.email, password: '' },
              token,
              null,
              args.item.id
            )
              .then((response: any) => {
                let user: any = response.data;
                Common.setItem('token', token);
                Common.setItem('refreshtoken', refreshtoken);
                Common.setItem('user', user);
                // console.log('frontend-version', REACT_APP_VERSION);
                // console.log('headers', response.headers);
                //     console.log(
                //       'backend-version',
                //       response.headers['x-custom-header-frontendversion']
                //     );
                //     if (splashInterval < maxInterval) {
                //       setTimeout(() => {
                //         clearInterval(intrvl);
                //         resolve();
                //       }, (maxInterval - splashInterval) * 1000);
                //     } else {
                //       clearInterval(intrvl);
                //       resolve();
                //     }
                window.location.reload();
              })
              .catch((error: any) => {
                console.error('error in getting fulljson');
              });
          })
          .catch((error: any) => {
            console.error('error in getting structure');
          });
      }
    };

    const onOpen = (args: any) => {
      setTimeout(() => {
        const elem: any = args.element.parentElement;
        elem.style.left =
          ddb.element.getBoundingClientRect().right - elem.offsetWidth + 'px';
        //elem.style.right = '100px';
      }, 50);
    };

    return (
      // <DropDownButtonComponent
      //   items={items}
      //   ref={(scope) => {
      //     ddb = scope;
      //   }}
      //   select={onSelect}
      //   open={onOpen}
      //   cssClass='mx-2'

      // >
      //   {/* <AllIonicons.PersonCircleSharp /> */}
      //   <i className={this.props.mainIcon} style={{ fontSize: '24px' }} />
      // </DropDownButtonComponent>
      <MenuComponent
        ref={(scope) => {
          ddb = scope;
        }}
        items={[
          {
            id: this.props.mainIcon,
            text: '',
            iconCss: `${this.props.mainIcon} font-24 lightblack `,
            items: items,
          },
        ]}
        select={onSelect}
        //beforeOpen={onOpen}
        cssClass='mx-2'
      ></MenuComponent>
    );
  }
}

export default DPButton;
