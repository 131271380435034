import React from 'react';
// import './Dashboard.css';
import './StickyBtn.css';
import { FabComponent } from '@syncfusion/ej2-react-buttons';
import Common from '../../../Util/Common';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
const { REACT_APP_ENDPOINT_ATTENDANCESERVICE } = process.env;

export default class StickyBtn extends React.Component {
  private count: number = 8;
  private user: any;
  private token: any = null;
  private EmpId: string = '';
  private ShiftId: string = '';
  private workingHour: number = 0;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    if (this.user.employee != null) {
      if (this.user.employee.id != null && this.user.employee.id != '') {
        this.GetAttendanceData(
          this.user.employee.id,
          this.user.employee.employeeShift[0].shiftId,
          Common.RemoveTimezoneOffsetFromDate(new Date())
        );
      }
    }
  }
  state = {
    clock: '',
  };
  componentDidMount(): void {}
  private GetAttendanceData(
    employeeId: string,
    shiftId: string,
    logindatetime: Date
  ) {
    try {
      var payload: any = {
        employeeId: employeeId,
        shiftId: shiftId,
        StartDate: logindatetime,
        EndDate: logindatetime,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/getall${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((resopnse: any) => {
          let logindata = resopnse;
          // let btnAttandance: any = document.getElementById('btnAttandance');
          if (logindata != undefined && logindata.length > 0) {
            if (logindata[logindata.length - 1].typeid == 1) {
              // CHECK IN
              this.setState({
                clock: 'Clock Out',
              });
            } else if (logindata[logindata.length - 1].typeid == 2) {
              // CHECK OUT
              this.setState({
                clock: 'Clock In',
              });
            }
          } else {
            this.setState({
              clock: 'Clock In',
            });
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    } catch (error) {
      console.error(error);
    }
  }

  private AddAttendance(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/add${
        !this.user.isManager ? '/ess' : ''
      }`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response;
      })
      .then((resopnse: any) => {
        if (resopnse.status == 200) {
          let TimeDisplay: any = document.getElementById('timedisplay');
          if (TimeDisplay) {
            if (this.state.clock == 'Clock In') {
              TimeDisplay.textContent = `Clocked In: ${Common.formatDate(
                new Date(resopnse.data.logindatetime),
                'HH:mm'
              )}`;
              this.setState({
                clock: 'Clock Out',
              });
            } else if (this.state.clock == 'Clock Out') {
              TimeDisplay.textContent = `Clocked Out:  ${Common.formatDate(
                new Date(resopnse.data.logindatetime),
                'HH:mm'
              )}`;
              this.setState({
                clock: 'Clock In',
              });
            }
          } else {
            if (this.state.clock == 'Clock In') {
              this.setState({
                clock: 'Clock Out',
              });
            } else if (this.state.clock == 'Clock Out') {
              this.setState({
                clock: 'Clock In',
              });
            }
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private handleClick(args: any) {
    var payload: any = {
      employeeId: this.user.employee.id,
      shiftId: this.user.employee.employeeShift[0].shiftId,
      logindatetime: Common.RemoveTimezoneOffsetFromDate(new Date()),
      sourcetype: 1,
      baseWorkHours:
        this.user.employee.employeeShift[0].shift.shiftTran[0].workingHour,
      remark: '',
    };
    if (this.state.clock == 'Clock Out') {
      DialogConfirm.showDialog({
        isConfirm: true,
        showCloseIcon: true,
        title: '',
        xPosition: 'right',
        yPosition: 'bottom',
        content:
          '<p class="dialog-contain"></p> <input  id="inputNote" type="text" name="inputOutNote" class="e-input bottom-rejecion" placeholder="Add optional note" multiline={true} autoComplete="off" />',
        okCaption: 'Clockout',
        closeCaption: '',
        dialogcssClass: 'clock-dialog',
        OkEvent: () => {
          const remark = (
            document.getElementById('inputNote') as HTMLInputElement
          ).value;
          payload.remark = remark;
          this.AddAttendance(payload);
        },
      });
    } else if (this.state.clock == 'Clock In') {
      this.AddAttendance(payload);
    }
  }
  render(): React.ReactNode {
    return (
      <>
        <div id='divbtn'>
          {this.user.employee != null ? (
            <FabComponent
              id='btnAttandance'
              iconCss='fa-regular fa-clock'
              key={Math.random()}
              position='BottomRight'
              onClick={this.handleClick.bind(this)}
              cssClass='fab-hover'
              content={`<span class="text-container"><span class="textEle">${this.state.clock}</span></span>`}
            ></FabComponent>
          ) : null}
        </div>
      </>
    );
  }
}
