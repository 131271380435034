// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * SUHAIL - 2023-03-22 - 120 - Research and Developement to show search bar below to selector
// * SUHAIL - 2023-03-30 - 120 - Reaseah on alignong search textbox and edit and buttons
// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import {
  ItemDirective,
  ItemsDirective,
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
  ToolbarComponent,
} from '@syncfusion/ej2-react-navigations';
import {
  SearchSettings,
  TreeGridComponent,
} from '@syncfusion/ej2-react-treegrid';
import React, { Component } from 'react';

// * UNCOMMENT IF XML2JS ARE REQUIRED
const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

// * ------------------------------------------------------------------------------------------------------------------3.Style

// * -----------------------------------------------------------------------------------------------------------------DOTENV VAR DECLARATION

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
interface Props {
  grid_id: string;
  showMoreOptions: boolean;
  SearchTextPlaceHolder: string;
}

// * -------------------------------------------------------------------------------------------------------------------CLASS DECLARATION
class SelectorSearch extends React.Component<Props> {
  // * ------------------------------------------------------------------------------------------------------------------2.Initialize Component

  constructor(props: any) {
    super(props);
  }

  static defaultProps = {
    showMoreOptions: false,
    SearchTextPlaceHolder: 'Search',
  };

  // * ------------------------------------------------------------------------------------------------------------------5.State
  state = {};

  // * ------------------------------------------------------------------------------------------------------------------Event Handler
  componentDidMount() {}

  // * -------------------------------------------------------------------------------------------------------------------4.Api

  // * ------------------------------------------------------------------------------------------------------------------6.Adapter

  // * --------------------------------------------------------------------------------------------------------------------HTML
  // * SUHAIL - 2023-04-07 - 130
  // * SUHAIL - 2023-04-24 - 60
  render() {
    const data: string[] = ['25%', '50%', '75%', '100%'];
    let textboxObj: any;

    const textBox = () => {
      return (
        <div>
          <TextBoxComponent
            ref={(scope) => {
              textboxObj = scope;
            }}
            placeholder='Find Text'
            showClearButton={true}
            created={onCreate.bind(this)}
            onChange={onSearchTextChange}
          ></TextBoxComponent>
        </div>
      );
    };

    function onCreate() {
      // if (textboxObj !== null) {
      //     textboxObj.addIcon('prepend', 'e-icons e-search');
      // }
    }

    const onSearchTextChange = (e: any) => {
      let TreeGridObj: TreeGridComponent | any;
      let obj: any = document.getElementById(this.props.grid_id) as Element;
      TreeGridObj = obj.ej2_instances[0] as TreeGridComponent;
      // let src: SearchSettings = TreeGridObj.searchSettings;
      // src.hierarchyMode = 'Both';
      // src.ignoreCase = true;
      // TreeGridObj!.searchSettings = src;
      TreeGridObj?.search(e.value);
    };

    return (
      <div
        id='selectorsearchmain'
        className='control-pane'
        style={{ border: '1px solid lightgray' }}
      >
        <div className='control-section tbar-control-section'>
          {/* <div className='control toolbar-sample tbar-sample' style={{ margin: '150px 0', width: '100%', maxWidth: '100%' }}> */}
          {/* Render the Toolbar Component with Popup mode */}
          {/* <ToolbarComponent overflowMode='Popup' cssClass='template'>
                        <ItemsDirective>
                            <ItemDirective cssClass='find toolbarleft-width' type="Input" template={textBox} overflow="Show" align="Left" />
                            <ItemDirective prefixIcon='e-icons e-annotation-edit' tooltipText='Edit Annotations' text='Edit' showTextOn='Overflow' align="Right" />
                            <ItemDirective prefixIcon='e-icons e-print' tooltipText='Print File' text='Print' showTextOn='Overflow' align="Right" />                            
                        </ItemsDirective>                        
                    </ToolbarComponent> */}
          {/* <ItemDirective prefixIcon='e-icons e-download' tooltipText='Download' text='Download' showTextOn='Overflow' align="Right" /> */}
          <div className='col-md-12 d-flex p-0'>
            <div style={{ width: '100%' }}>
              <span
                className='e-input-group e-control-wrapper e-inherit border-0 px-1'
                style={{ background: 'none' }}
              >
                <TextBoxComponent
                  ref={(scope) => {
                    textboxObj = scope;
                  }}
                  //placeholder='Search'
                  placeholder={this.props.SearchTextPlaceHolder}
                  autocomplete='off'
                  showClearButton={true}
                  onChange={onSearchTextChange}
                  title='Search'
                ></TextBoxComponent>
                <span
                  id={`${this.props.grid_id}-search`}
                  className='e-icons e-search e-input-group-icon'
                  title='Search'
                ></span>
                {/* <span
                  id={`${this.props.grid_id}-edit`}
                  className='e-icons e-annotation-edit e-input-group-icon'
                  title='Edit'
                ></span>
                <span
                  id={`${this.props.grid_id}-print`}
                  className='e-icons e-print e-input-group-icon'
                  title='Print'
                ></span> */}
                {this.props.showMoreOptions ? (
                  <span
                    id={`${this.props.grid_id}-moreoptions`}
                    className='my-1 me-1 three-dots'
                    title='More Options'
                  ></span>
                ) : null}
              </span>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default SelectorSearch;
